import React, {cloneElement, ReactElement, useEffect, useRef, useState} from "react";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import {ClickAwayListener} from "@mui/base/ClickAwayListener";
import {PopperPlacementType} from "@mui/material/Popper";
import MenuListItem from "./menu-list-item";

const styles = {
  paper: {
    zIndex: 1301,
    padding: "8px",
    background: "white",
    borderRadius: "6px",
    boxShadow: "0 12px 28px 0 rgba(0,0,0,0.1), 0 2px 4px 0 rgba(0,0,0,0.05)",
    border: "1.5px solid rgb(240,240,240)",
    minWidth: "180px",
    boxSizing: "border-box",
  },
};
const MenuWrapper = ({
  children,
  items,
  live = false,
  style,
  disableToggle,
  placement,
  disablePadding,
  closeOnClick = false,
}: {
  children: React.ReactNode;
  items: React.ReactNode;
  live?: boolean;
  closeOnClick?: boolean;
  disablePadding?: boolean;
  style?: React.CSSProperties;
  disableToggle?: boolean;
  placement?: PopperPlacementType;
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [elements, setElements] = useState([]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = React.useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
    if (!live) {
      let elementsData = React.Children.toArray(items);
      elementsData = processElements(elementsData);
      setElements(elementsData);
    }
  }, [open]);

  const closeDialog = () => setOpen(false);

  let processElements = (
    reactElements: Array<Exclude<React.ReactNode, boolean | null | undefined>>
  ) => {
    reactElements.forEach((el, index) => {
      let element = el as ReactElement;
      if (element.type === MenuListItem) {
        reactElements[index] = cloneElement(element, {
          onClose: closeDialog,
          key: index,
        });
      } else {
        if (element.props) {
          let possibleFields = React.Children.toArray(element.props.children);
          if (possibleFields.length > 0) {
            possibleFields = processElements(possibleFields);
          }
          reactElements[index] = cloneElement(element, {
            children: possibleFields,
          });
        }
      }
    });
    return reactElements;
  };

  let processedItems = [];
  if (live) processedItems = processElements(React.Children.toArray(items));

  return (
    <div>
      <div
        ref={anchorRef}
        aria-controls={open ? "menu" : undefined}
        aria-haspopup="true"
        onClick={disableToggle ? handleOpen : handleToggle}
        style={{cursor: "pointer"}}
      >
        {children}
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement={placement != null ? placement : "bottom-end"}
        transition
        disablePortal
        style={{zIndex: 1301, ...style}}
      >
        {({TransitionProps, placement}) => (
          <Fade
            {...TransitionProps}
            style={{transformOrigin: placement === "bottom" ? "center top" : "center bottom"}}
          >
            <div
              style={{
                ...(styles.paper as React.CSSProperties),
                padding: disablePadding ? 0 : "8px",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  onClick={
                    closeOnClick
                      ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                          handleClose(event.nativeEvent)
                      : undefined
                  }
                >
                  {live ? processedItems : elements}
                </div>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default MenuWrapper;
