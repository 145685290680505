import React from "react";

function ListContainer ({children, padding, style}: {children: React.ReactNode, padding?: string | number, style?: React.CSSProperties}) {
    return (
        <div style={{
            border: '2px solid rgb(239,239,239)',
            borderRadius: "10px",
            overflow: "hidden",
            padding: padding ?? "",
            display: "flex",
            alignItems: "center", justifyContent: "center",
            flexDirection: "column",
            ...style
        }}>
            {children}
        </div>
    )
}

export default ListContainer;