import React from "react";
import {DialogProps} from "@mui/material/Dialog";
import {Theme} from "@mui/material/styles";
import Text from "../data-display/text";
import {AiOutlineClose} from "react-icons/ai";
import {IoMdArrowBack} from "react-icons/io";
import {useTheme} from "@mui/material";
import {Dialog,DialogTitle, DialogContent} from "@mui/material";
import {useMediaQuery} from "@mui/material";
import CustomIconButton from "../buttons/custom-icon-button";

interface DialogWrapperProps extends Omit<DialogProps, 'onSubmit'> {
    onClose: () => void,
    onReturn?: () => void,
    suffixIcon?: React.ReactNode,
    root?: boolean,
    disablePadding?: boolean,
    disableBackdrop?: boolean,
    height?: string
}

const DialogWrapper = ({ open, onClose, title, children, root, disablePadding, onReturn, suffixIcon = <div />, disableBackdrop = false, height = "500px" }: DialogWrapperProps) => {
    const theme = useTheme();
    const styles = {
      dialog: {
        position: "relative",
        zIndex: 2600,
        background: theme.palette.background.default,
        boxSizing: "border-box",
        height: "auto",
        borderRadius: "10px",
        padding: "0",
        display: "flex",
        boxShadow: "none",
        flexDirection: "column",
        transition: theme.transitions.create(["box-shadow"], {
          easing: theme.transitions.easing.easeInOut,
          duration: "0.3s",
        }),
        [theme.breakpoints.down("sm")]: {
          height: "100%",
          borderRadius: "0px",
        },
      },
    };
    const xsDown = useMediaQuery(theme.breakpoints.down("sm"));
    const handleOnClick = () => {
      if (root) {
        onClose();
      } else {
        onReturn();
      }
    };
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={"xs"}
        fullWidth
        slotProps={{
          backdrop: {
            style: {
              background: !disableBackdrop ? "rgba(0, 0, 0, 0.5)" : "rgb(0,0,0,0)",
            },
          },
        }}
        PaperProps={{
          style: {
            ...(styles.dialog as React.CSSProperties),
            zIndex: 2000,
          },
        }}
        fullScreen={xsDown}
      >
        <DialogTitle
          style={{
            borderBottom: "1.5px solid rgb(220,220,220)",
            position: "relative",
            padding: "12px 24px",
          }}
        >
          <div style={{display: "flex", width: "100%"}}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
              <CustomIconButton
                style={{transform: "translateX(-3px)", marginRight: "12px"}}
                onClick={handleOnClick}
              >
                <AiOutlineClose
                  style={{
                    opacity: root ? 1 : 0,
                    transform: `rotate(${root ? "0deg" : "-15deg"})`,
                    transition: "0.3s",
                  }}
                />
                <IoMdArrowBack
                  style={{
                    position: "absolute",
                    opacity: !root ? 1 : 0,
                    transform: `rotate(${!root ? "0deg" : "15deg"})`,
                    transition: "0.3s",
                  }}
                />
              </CustomIconButton>
              <Text variant="body1" black component={"span"}>
                {title}
              </Text>
            </div>
            <div style={{marginLeft: "auto"}} />
            {suffixIcon}
          </div>
        </DialogTitle>
        <DialogContent
          style={{maxHeight: xsDown ? "unset" : height, padding: disablePadding ? "0px" : "auto"}}
        >
          {children}
        </DialogContent>
      </Dialog>
    );
}

export default DialogWrapper;


