 import { cloneElement, ReactElement, useRef } from "react";
 import React from "react";
 import FormErrorText from "../data-display/form-error-text";
 import FormInputField from "../forms/form-input";
 import MiniButton from "../buttons/mini-button";
 import PrimaryButton from "../buttons/primary-button";
 import FormSwitch from "../input-feilds/form-switch";
import {IconButton} from "@mui/material";
 import DialogBottomButton from "../dialogs/dialog-bottom-button";
 import FormCheckbox from '../input-feilds/form-checkbox';
 import SecondaryButton from "../buttons/secondary-button";
 import BudgetInput from "../task-components/offers/budget-input";
 import {isQuickTaskProject} from "../../utils/constant.urls";
 const telCheckLength = isQuickTaskProject ? 12 : 14;
 const styles = {
   form: {
     padding: "0 !important",
     width: "100%",
     display: "flex",
     flexDirection: "column",
   },
   padding: {
     padding: "24px !important",
   },
 };

 function FormWrapper({
   children,
   padding = false,
   initialValues = {},
   onSubmit,
   onValueChange,
   autoComplete,
   style,
   reset,
   disableLoading = false,
 }: {
   children: React.ReactNode;
   padding?: boolean;
   initialValues?: Record<string, string | boolean>;
   onSubmit: (values: Record<string, string | boolean>) => Promise<any>;
   onValueChange?: any;
   autoComplete?: string | undefined;
   style?: React.CSSProperties;
   reset?: boolean;
   disableLoading?: boolean;
 }) {
   // Loading state for form
   const [loading, setLoading] = React.useState<boolean>(false);

   // Flag to show / hide error texts and highlights
   const [showError, setShowError] = React.useState<boolean>(false);

   // Error object
   const [error, setError] = React.useState<Record<string, string | boolean>>({});

   // All values in an form retrieved based on the [name] prop from [InputField]
   const [values, setValues] = React.useState<Record<string, string | boolean>>(initialValues);

   // Form reference
   const formReference = useRef<HTMLFormElement>(null);

   // Submit is trigger on Form Submission which validates the fields based on given props.
   const submit = async (event: React.FormEvent<HTMLFormElement>) => {
     if (event && event.preventDefault) {
       event.preventDefault();
     }

     if (event && event.stopPropagation) {
       event.stopPropagation();
     }

     // Assuming all data is valid
     let validation = true;

     // Storing current error object
     let e: Record<string, string | boolean> = error;

     // Setting Show Error to false until validation is complete
     setShowError(false);

     let eventTarget = event.target as HTMLFormElement;
     let val = {};
     if (eventTarget.elements) {
       let elementsLength: number = eventTarget.elements.length;
       for (let i = 0; i < elementsLength; i++) {
         let element = eventTarget.elements[i] as HTMLInputElement;
         if (element.name !== "" && element.value !== "") {
           val[element.name] = element.value;
         }

         if (element.hasAttribute("name")) {
           switch (element.type) {
             case "checkbox":
               if (element.required && !element.checked) {
                 e[element.name] = "empty";
                 validation = false;
               } else {
                 e[element.name] = "";
               }
               break;
             case "email":
               if (element.required && !element.value.trim()) {
                 e[element.name] = "empty";
                 validation = false;
               } else if (
                 !element.value.match(
                   new RegExp(
                     /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                   )
                 )
               ) {
                 e[element.name] = "Please enter a valid Email Address.";
                 validation = false;
               } else {
                 e[element.name] = "";
               }
               break;
             case "date":
               if (element.required && !element.value) {
                 e[element.name] = "empty";
                 validation = false;
               } else {
                 e[element.name] = "";
               }
               break;
             case "tel":
               if (element.required && !element.value.trim()) {
                 e[element.name] = "empty";
                 validation = false;
               } else if (element.value.length !== telCheckLength) {
                 e[element.name] = "Please enter a valid Phone Number.";
                 validation = false;
               } else {
                 e[element.name] = "";
               }
               break;
             case "number":
             case "text":
             case "password":
             case "textarea":
               if (element.required && !element.value.trim()) {
                 e[element.name] = "empty";
                 validation = false;
               } else {
                 e[element.name] = "";
               }
               break;
             default:
               break;
           }
         }
       }
       await setError(e);
       setValues(val);
     }

     if (validation) {
       if (!disableLoading) await setLoading(true);
       await onSubmit(values)
         .then(async () => {
           if (reset) {
             if (formReference.current) formReference.current.reset();
             let v = {};
             for (let k in values) {
               if (values.hasOwnProperty(k)) v[k] = "";
             }
             setValues(v);
           }
           await setLoading(false);
           await setShowError(false);
         })
         .catch(async (err: Record<string, string | boolean> | string) => {
           if (typeof err === "string") {
             await setLoading(false);
             await setShowError(true);
             await setError({text: err});
           } else {
             await setLoading(false);
             await setShowError(true);
             await setError(err);
           }
         });
     } else {
       setLoading(false);
       setShowError(true);
     }
   };

   const handleChange = (event: React.FormEvent) => {
     let evenTarget = event.target as HTMLInputElement;
     setError({...error, [evenTarget.name]: "", text: ""});
     setValues({
       ...values,
       [evenTarget.name]: evenTarget.type === "checkbox" ? evenTarget.checked : evenTarget.value,
     });
     if (onValueChange || true) {
       onValueChange({
         ...values,
         [evenTarget.name]: evenTarget.type === "checkbox" ? evenTarget.checked : evenTarget.value,
       });
     }
   };

   const handleAlienChange = () => {
     setError({});
   };

   let processElements = (
     reactElements: Array<Exclude<React.ReactNode, boolean | null | undefined>>
   ) => {
     reactElements.forEach((el, index) => {
       let element = el as ReactElement;
       if (element.type === FormInputField)
         reactElements[index] = cloneElement(element, {
           disabled: loading,

           onChange: handleChange,
           value: values[element.props.name] ?? undefined,
           error: showError ? error[element.props.name] : "",
           key: index,
         });
       else if (element.type === FormSwitch)
         reactElements[index] = cloneElement(element, {
           disabled: loading,
           onChange: handleChange,
           checked: values[element.props.name] ?? undefined,
           key: index,
         });
       else if (element.type === FormCheckbox)
         reactElements[index] = cloneElement(element, {
           disabled: loading,
           onChange: handleChange,
           checked: values[element.props.name] ?? false,
           error: showError ? error[element.props.name] : "",
           key: index,
         });
       else if (element.type === BudgetInput)
         reactElements[index] = cloneElement(element, {
           onChange: handleAlienChange,
           key: index,
         });
       else if (
         element.type === PrimaryButton ||
         element.type === SecondaryButton ||
         element.type === MiniButton ||
         element.type === IconButton ||
         element.type === DialogBottomButton
       ) {
         if (!element.props.isLoading) {
           reactElements[index] = cloneElement(element, {
             isLoading: loading,
             type: "submit",
             key: index,
           });
         }
       } else if (element.type === FormErrorText)
         reactElements[index] = cloneElement(element, {
           error: error,
           showError: showError,
           key: index,
         });
       else {
         if (element.props) {
           let possibleFields = React.Children.toArray(element.props.children);
           if (possibleFields.length > 0) {
             possibleFields = processElements(possibleFields);
           }
           reactElements[index] = cloneElement(element, {
             children: possibleFields,
           });
         }
       }
     });
     return reactElements;
   };

   let elements = React.Children.toArray(children);
   elements = processElements(elements);
   const styleValue = {
     ...(styles.form as React.CSSProperties),
     ...(padding && (styles.padding as React.CSSProperties)),
   };
   return (
     <form
       ref={formReference}
       onSubmit={submit}
       autoComplete={autoComplete}
       noValidate
       style={{...styleValue, ...style}}
     >
       {elements}
     </form>
   );
 }

 export default FormWrapper;
