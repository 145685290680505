// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-quick-task-about-section-tsx": () => import("./../../../src/pages/about-us/QuickTaskAboutSection.tsx" /* webpackChunkName: "component---src-pages-about-us-quick-task-about-section-tsx" */),
  "component---src-pages-about-us-syde-about-section-tsx": () => import("./../../../src/pages/about-us/SydeAboutSection.tsx" /* webpackChunkName: "component---src-pages-about-us-syde-about-section-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-actions-index-tsx": () => import("./../../../src/pages/actions/index.tsx" /* webpackChunkName: "component---src-pages-actions-index-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-forget-password-index-tsx": () => import("./../../../src/pages/forget-password/index.tsx" /* webpackChunkName: "component---src-pages-forget-password-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-page-company-trustand-savety-tsx": () => import("./../../../src/pages/landing-page/company/TrustandSavety.tsx" /* webpackChunkName: "component---src-pages-landing-page-company-trustand-savety-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-messaging-index-tsx": () => import("./../../../src/pages/messaging/index.tsx" /* webpackChunkName: "component---src-pages-messaging-index-tsx" */),
  "component---src-pages-my-tasks-index-tsx": () => import("./../../../src/pages/my-tasks/index.tsx" /* webpackChunkName: "component---src-pages-my-tasks-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-privacy-policy-quik-tasksection-2-jsx": () => import("./../../../src/pages/privacy-policy/QuikTasksection2.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-quik-tasksection-2-jsx" */),
  "component---src-pages-privacy-policy-quik-tasksection-jsx": () => import("./../../../src/pages/privacy-policy/QuikTasksection.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-quik-tasksection-jsx" */),
  "component---src-pages-privacy-policy-syde-taskersection-jsx": () => import("./../../../src/pages/privacy-policy/SydeTaskersection.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-syde-taskersection-jsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-support-index-tsx": () => import("./../../../src/pages/support/index.tsx" /* webpackChunkName: "component---src-pages-support-index-tsx" */),
  "component---src-pages-tasks-index-tsx": () => import("./../../../src/pages/tasks/index.tsx" /* webpackChunkName: "component---src-pages-tasks-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */),
  "component---src-pages-terms-quick-task-terms-tsx": () => import("./../../../src/pages/terms/QuickTaskTerms.tsx" /* webpackChunkName: "component---src-pages-terms-quick-task-terms-tsx" */),
  "component---src-pages-terms-syde-tasker-terms-tsx": () => import("./../../../src/pages/terms/SydeTaskerTerms.tsx" /* webpackChunkName: "component---src-pages-terms-syde-tasker-terms-tsx" */),
  "component---src-pages-verify-email-verification-wrapper-tsx": () => import("./../../../src/pages/verify-email/verification-wrapper.tsx" /* webpackChunkName: "component---src-pages-verify-email-verification-wrapper-tsx" */),
  "component---src-templates-article-data-tsx": () => import("./../../../src/templates/article-data.tsx" /* webpackChunkName: "component---src-templates-article-data-tsx" */),
  "component---src-templates-blog-view-tsx": () => import("./../../../src/templates/blog-view.tsx" /* webpackChunkName: "component---src-templates-blog-view-tsx" */),
  "component---src-templates-category-data-tsx": () => import("./../../../src/templates/category-data.tsx" /* webpackChunkName: "component---src-templates-category-data-tsx" */),
  "component---src-templates-services-data-tsx": () => import("./../../../src/templates/services-data.tsx" /* webpackChunkName: "component---src-templates-services-data-tsx" */),
  "component---src-templates-task-data-tsx": () => import("./../../../src/templates/task-data.tsx" /* webpackChunkName: "component---src-templates-task-data-tsx" */)
}

