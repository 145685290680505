
export const environment = {
	production: false,
	projectId: 'Quick_task',
	firebaseConfig: {
		apiKey: '',
		authDomain: '',
		databaseURL: '',
		projectId: '',
		storageBucket: '',
		messagingSenderId: '',
		appId: '',
	},
	isRoot: false,
	mainColor: '#26A2A7',
	mainColorRGB: "rgb(38, 162, 167)",
	outlineColorRGB: "rgb(38, 162, 167 ,.6)",
	secondaryColor: '#142249',
	thirdColor: '#548389',
	thirdColoRGB: 'rgb(84, 131, 137)',
	grayColor: '#595F72',
	whiteColor: '#FFFFFF',
	priceDarkColor: '#28B3A6',
	priceLightColor: '#EAFFFD',
	headerTitle: 'quickTask',
	apiUrl: '',
	mainVideo:'quickTaskAssets/Video/QuickTask_Home_Page_Video.mp4'
	,ThumbnailImage:"quickTaskAssets/CTA.png"
	,PlayStore:"quickTaskAssets/QuickTask Logo ull white.png"
	, googleStore: "quickTaskAssets/QuickTask Logo ull white.png"
	, companyInfo: {
		faceBookLink: 'https://www.facebook.com/QuickTask.co.uk',
        instagramLink : 'https://www.instagram.com/quicktask.co.uk',
        TiktokLink:'https://www.tiktok.com/@quicktask.co.uk',
        Name: 'QuickTask LTD',
        Comapny_number : '15395331',
		Address: {
		street:'128 City Road',
        city:'London',
			extraddressinfo: 'EC1V 2NX'
		,completeAddress:"128 City Road London EC1V 2NX"
		}
	}
};
