import {UserData} from "../../models/user-data";
import firebase from "firebase/app";
import Address from "../../models/address";

// Action Types
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_USERS_DATA = 'UPDATE_USERS_DATA';
export const CLEAR_ADDRESS = 'CLEAR_ADDRESS';
interface SetUser {
    type: typeof SET_USER,
    payload: {
        user: firebase.User | null,
        userData: UserData | null
    }
}

interface UpdateUser {
    type: typeof UPDATE_USER,
    payload: {
        userData?: UserData,
    }
}

interface UpdateUsersData {
    type: typeof UPDATE_USERS_DATA,
    payload: UserData[]
       
}

interface UpdateAddress {
    type: typeof UPDATE_ADDRESS,
    payload: {
        defaultBillingAddressID?: string,
        defaultTaskAddressID?: string,
        addresses?: Address[],
        addressLoading?: boolean,
    }
}
interface ClearAddress {
    type: typeof CLEAR_ADDRESS,
    payload: {}
}

export type UserActionTypes = SetUser | UpdateUser | UpdateAddress |ClearAddress | UpdateUsersData

// State
export interface UserState {
    loading?: boolean,
    addressLoading?: boolean,
    userData: UserData | null,
    usersData: UserData[],
    isAuthenticated?: boolean,
    isVerified?: boolean,
    providers?: (firebase.UserInfo | null)[] | null,
    addresses?: Address[],
    defaultTaskAddressID?: string | null,
    defaultBillingAddressID?: string | null
}