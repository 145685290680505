import React, {useState, useEffect} from "react";
import Text from "../../../components/data-display/text";
import {useTheme} from "@mui/system";
import {PostTaskProps} from "../post-task-wrapper";
import FormWrapper from "../../forms/form-wrapper";
import ListContainer from "../../../components/list-handlers/list-container";
import {tasks} from "../../../utils/firebase.utils";
import FormErrorText from "../../data-display/form-error-text";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import {AssignedWorker, Geoloc} from "../../../models/task";
import BudgetInput, {BudgetData} from "../../../components/task-components/offers/budget-input";
import DialogBottomButton from "../../../components/dialogs/dialog-bottom-button";
import NumberFormat, {NumericFormat} from "react-number-format";
import question from "../../../images/icons/question-mark.png";
import {navigate} from "gatsby";
import {currencySymbol, isQuickTaskProject, project} from "../../../utils/constant.urls";
import {environment} from "../../../environments/quickTaskEnvironemt";
const QuikTaskMainColor = environment.mainColor;
const TaskBudgetForm = ({task, onContinue, saveNewTaskId, updateTask}: PostTaskProps) => {
  const theme = useTheme();
  const palette_Color = isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main;
  const {defaultTaskAddressID, addresses, userData} = useSelector(
    (state: RootState) => state.userData
  );
  const [hasPhoneNumber, setHasPhoneNumber] = useState<boolean>(
    userData?.phoneNumber && userData?.phoneNumber?.length > 3 ? true : false
  );
  const [budgetData, setBudgetData] = useState<BudgetData>(
    new BudgetData({
      budgetType: "total",
      totalBudget: "0",
      hourlyBudget: "0",
      hours: 1,
      noOfTaskers: 1,
    })
  );

  const postTask = () => {
    return new Promise((resolve, reject) => {
      if (budgetData.errors) {
        reject(budgetData.errors);
      } else {
        if (budgetData.budgetType === "total") {
          task.budget = budgetData.budget();
        } else {
          task.budget = budgetData.budget();
          task.budgetByHour = budgetData.hourlyBudget;
          task.hours = budgetData.hours;
          task.taskerRequired = budgetData.noOfTaskers;
          task.isMultipleTasker = budgetData.isMultipleTaskers;
          task.isHourly = true;
        }

        task.createAt = new Date();
        task.priority = 0;
        task.project = project;

        if (!task.isRemote) {
          task.address = addresses.find((a) => a.id === defaultTaskAddressID);
          task.city = addresses.find((a) => a.id === defaultTaskAddressID).secondaryLine;
          task._geoloc = new Geoloc({
            lat: addresses.find((a) => a.id === defaultTaskAddressID).lat,
            lng: addresses.find((a) => a.id === defaultTaskAddressID).lng,
          });
        }
        task.assignedWorker = new AssignedWorker();
        if (hasPhoneNumber) {
          let t = JSON.parse(JSON.stringify(task.toJson()));
          t["createAt"] = new Date(t["createAt"]);
          t["dateToStart"] = new Date(t["dateToStart"]);
          tasks
            .add(t)
            .then((doc) => {
              saveNewTaskId(doc?.id);
              onContinue();
            })
            .catch((e) => console.log(e));
        } else {
          let t = task;
          updateTask(t);
          onContinue();
        }
      }
    });
  };

  const openInNewTab = () => {
    window.open(
      "/support/posters/post-a-task/before-posting/what-is-the-estimated-pricing-for-my-tasks",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div>
      <FormWrapper onSubmit={postTask} style={{marginTop: "16px", position: "unset"}}>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Text variant={"body2"} style={{color: palette_Color, marginBottom: "4px"}} medium>
            What's your budget?
          </Text>
          {!isQuickTaskProject && (
            <div
              onClick={openInNewTab}
              style={{
                background: `url(${question}) center center / cover`,
                height: "18px",
                width: "18px",
              }}
            />
          )}
        </div>

        <Text variant={"body2"} style={{color: "grey", marginBottom: "8px"}}>
          Please set a budget that you would pay to complete your task. This will help guide Taskers
          on how much to offer.
        </Text>
        <BudgetInput
          task={task}
          initialBudgetData={new BudgetData()}
          onBudgetChange={(b) => {
            let t = new BudgetData(b.toJson());
            setBudgetData(t);
          }}
        />
        <div style={{height: "8px"}} />
        <FormErrorText />
        <DialogBottomButton
          type={"submit"}
          data={
            <ListContainer
              style={{
                padding: "12px",
                width: "90%",
                marginBottom: "12px",
                boxSizing: "border-box",
                background: "white",
              }}
            >
              <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                <div
                  style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}
                >
                  <div>
                    <Text variant={"body2"} bold component={"body2"} selectable={false}>
                      Estimated budget{" "}
                      {budgetData.isMultipleTaskers ? `  ( × ${budgetData.noOfTaskers} )` : ""}
                    </Text>
                  </div>
                  <div>
                    <Text variant={"caption"} style={{color: "grey"}} medium selectable={false}>
                      Final payment will be agreed on later.
                    </Text>
                  </div>
                </div>
                <Text
                  variant={"h5"}
                  style={{display: "flex", alignItems: "center", marginRight: "8px", flexShrink: 0}}
                  bold
                  component={"h6"}
                  selectable={false}
                >
                  <Text component={"p"} variant={"body2"} style={{marginRight: "2px"}} bold>
                    {currencySymbol}
                  </Text>
                  {budgetData.budget() ? (
                    <NumericFormat
                      value={parseFloat(budgetData.budget()).toFixed(0)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={""}
                    />
                  ) : (
                    0
                  )}
                </Text>
              </div>
            </ListContainer>
          }
        >
          Post Task
        </DialogBottomButton>
      </FormWrapper>
    </div>
  );
};

export default TaskBudgetForm;
