import Text from "../data-display/text";
import Icon from "react-eva-icons";
import React from "react";
import {useTheme} from "@mui/system";
import Address from "../../models/address";


// function AddressItem({ address, onClick, editable = false, last = false,deleteAddress }: { address: Address; onClick: () => void; deleteAddress: any;last?: boolean; editable?: boolean }) {
  function AddressItem({ address, onClick, editable = false, last = false }: { address: Address; onClick: () => void;last?: boolean; editable?: boolean }) {

  const theme = useTheme();
const styles = {
  listItem: {
    cursor: "pointer",
    boxSizing: "border-box",
    padding: "12px 24px",
    display: "flex",
    alignItems: "flex-start",
    position: "relative",
    width: "100%",
    background: "white",
    transition: "0.3s",
    "&:hover": {
      background: "rgb(250,250,250)",
    },
  },
  editIcon: {
    position: "absolute",
    top: 12,
    right: 4,
    width: "24px",
    height: "24px",
  },
};
  return (
    <div
      onClick={onClick}
      style={{
        ...(styles.listItem as React.CSSProperties),
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: last ? "" : "2px solid rgb(239,239,239)",
        background: editable ? "transparent" : "white",
        padding: editable ? "8px 8px" : "12px 24px",
      }}
    >
      <div style={{marginTop: "6px"}}>
        <div style={{marginLeft: "16px"}}>
          <Text variant={"body2"} component={"p"}>
            {address ? (
              <Icon
                style={{marginLeft: "16px"}}
                name={"pin-outline"}
                size="medium"
                fill={theme.palette.primary.main}
              />
            ) : (
              ""
            )}
            {address?.primaryLine}
          </Text>
          <div style={{float: "right", marginRight: "10px"}}></div>
          <Text variant={"body2"} component={"p"} style={{opacity: "0.6"}}>
            {address?.secondaryLineWithZipCode}
          </Text>
        </div>
      </div>
      {/* {address && !editable? <div onClick={()=>deleteAddress(address)}>
      <Close  style={{ opacity:  1 , transform: `rotate( "0deg")`, transition: "0.3s",marginLeft: "16px",color:theme.palette.primary.main  }} />
        </div>:""} */}
      {editable && (
        <div style={{...(styles.editIcon as React.CSSProperties)}}>
          <Icon name={"edit-outline"} size="medium" fill={"grey"} />
        </div>
      )}
    </div>
  );
}

export default AddressItem;