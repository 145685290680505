import React, {CSSProperties} from "react";
import {Link} from "gatsby";

const styles = {
  avatar: {
    borderRadius: "50%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "0.3s",
    position: "relative",
    zIndex: 3,
    flexShrink: 0,
  },
};

const Avatar = ({
  name,
  profilePicture,
  userId,
  style,
  size = 24,
}: {
  name?: string;
  userId?: any;
  profilePicture?: string | null;
  style?: CSSProperties;
  size?: number;
}) => {
  const getGradients = (name: string) => {
    let code: number =
      (name?.length ? name.length : 0) > 0 ? name.toUpperCase()[0].charCodeAt(0) : 1;
    code += (name?.length ? name.length : 0) > 1 ? name.toUpperCase()[1].charCodeAt(0) : 0;

    let colors: string[][] = [
      ["rgba(150, 222, 218, 1.0)", "rgba(80, 201, 195, 1.0)"],
      ["rgba(102, 126, 254, 1.0)", "rgba(148, 75, 192, 1.0)"],
      ["rgba(255, 177, 153, 1.0)", "rgba(255, 8, 68, 1.0)"],
      ["rgba(100, 210, 255, 1.0)", "rgba(10, 60, 255, 1.0)"],
      ["rgba(83, 120, 149, 1.0)", "rgba(9, 32, 63, 1.0)"],
      ["rgba(48, 210, 190, 1.0)", "rgba(53, 132, 167, 1.0)"],
      ["rgba(255, 179, 169, 1.0)", "rgba(245, 103, 144, 1.0)"],
    ];

    return colors[code % colors.length];
  };

  const defaultStyles = {
    minWidth: size.toString() + "px",
    minHeight: size.toString() + "px",
    maxWidth: size.toString() + "px",
    maxHeight: size.toString() + "px",
    boxShadow: `0 calc(0.05 * ${size.toString()}px) 16px 0 rgba(0,0,0,0.09)`,
    border: `calc(0.095 * ${size.toString()}px) solid white`,
    ...style,
  };

  return (
    <Link to={userId ? `/profile${userId}` : "/"}>
      {!profilePicture && !name ? (
        <div
          style={{
            ...(styles.avatar as React.CSSProperties),
            background: `rgb(200,200,200)`,
            ...defaultStyles,
          }}
        />
      ) : profilePicture ? (
        <div
          style={{
            ...(styles.avatar as React.CSSProperties),

            backgroundImage: `url(${profilePicture})`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundColor: `${getGradients(name)[0]}`,
            ...defaultStyles,
          }}
        />
      ) : (
        <div
          style={{
            ...(styles.avatar as React.CSSProperties),
            background: `linear-gradient(150deg, ${getGradients(name).join(",")})`,
            ...defaultStyles,
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: `${(size / 1.9).toFixed(2).toString()}px`,
              fontFamily: "'HKGroteskBlack', sans-serif",
            }}
          >
            {name && name.length > 0 ? name[0].toUpperCase() : ""}
          </div>
        </div>
      )}
    </Link>
  );
};

export default Avatar;
