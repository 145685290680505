import React, {useEffect, useState} from "react";
import Text from "../../../components/data-display/text";
import {useTheme} from "@mui/system";
import {PostTaskProps} from "../post-task-wrapper";
import FormWrapper from "../../forms/form-wrapper";
import FormInput from "../../forms/form-input";
import { FaPlusCircle } from "react-icons/fa";
import { FiXCircle } from "react-icons/fi";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import DialogBottomButton from "../../../components/dialogs/dialog-bottom-button";
import { environment } from "../../../environments/quickTaskEnvironemt";
import { isQuickTaskProject } from "../../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;
const TaskRequirementsForm = ({task, updateTask, onContinue}: PostTaskProps) => {
    const theme = useTheme();
    const [requirements, setRequirements] = useState<string[]>([]);
const palette_Color = isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main;
    useEffect(() => {
        setRequirements(task.requirements ?? []);
    }, [task]);

    const addRequirements = (values: Record<string, any>) => {
        return new Promise((resolve, reject) => {
            let r = [...requirements];
            r.push(values.reqText);
            setRequirements(r);
            task.requirements = r;
            updateTask(task);
            resolve("Success");
        });
    };

    const removeRequirements = (index: number) => {
        let r = [...requirements];
        r.splice(index, 1);
        setRequirements(r);
        task.requirements = r;
        updateTask(task);
    };

    return (
        <div>
            <FormWrapper
                disableLoading
                reset
                onSubmit={addRequirements}
                initialValues={{
                    dateToStart: task.dateToStart as any,
                }}
                style={{marginTop: "16px", position: "relative"}}
            >
                <Text variant={"body2"} style={{color: palette_Color, marginBottom: "8px"}} medium>
                    Please add any Requirements the Task must have to complete your task.
                </Text>
                <FormInput
                    placeholder={"e.g. Tools or Vehicles"}
                    autoCapitalize={"sentences"}
                    name={"reqText"}
                    type={"text"}
                    required
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton type={"submit"} disableFocusRipple disableTouchRipple disableRipple
                                        style={{padding: "8px", marginRight: "12px", height: "32px", width: "32px"}}>
                                <FaPlusCircle fill={palette_Color}/>
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormWrapper>
            <div style={{height: "43vh", overflowX: "auto"}}>
                {requirements.map((r, i) => {
                    return (
                        <div key={i} style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "12px 16px",
                            borderBottom: "2px solid rgb(240,240,240)",
                            width: "100%",
                            boxSizing: "border-box",
                            justifyContent: "space-between"
                        }}>
                            <Text variant={"body2"} regular>
                                {r}
                            </Text>
                            <FiXCircle
                                size={18}
                                style={{color: theme.palette.error.main, width: "18px", cursor: "pointer"}}
                                onClick={() => {
                                    removeRequirements(i);
                                }}
                            />
                        </div>
                    );
                })}
            </div>
            <DialogBottomButton type={"submit"} onClick={onContinue}>
                Continue
            </DialogBottomButton>
        </div>
    );
};

export default TaskRequirementsForm;
