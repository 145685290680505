import React, {useEffect, useState} from "react";
import {AppBar} from "@mui/material";
import {Toolbar} from "@mui/material";
import Container from "@mui/material/Container";
import {useTheme} from "@mui/system";
import {Link} from "gatsby";
import InlineLink from "../data-display/inline-link";
import Text from "../data-display/text";
import Sydetasker from "../../images/sydework.png";
import Avatar from "../avatar/avatar";
import MenuWrapper from "../menu-popper/menu-wrapper";
import MenuListItem from "../menu-popper/menu-list-item";
import {HiMenuAlt4} from "react-icons/hi";
import {BsFillCaretDownFill} from "react-icons/bs";
import {FiChevronRight} from "react-icons/fi";
import Icon from "react-eva-icons";
import {useMediaQuery} from "@mui/material";
import {Grid} from "@mui/material";
import {UserData} from "../../models/user-data";
import NotificationsList from "../notifications-list/notifications-list";
import {auth,firestore} from "../../utils/firebase.utils";
import {HiBell} from "react-icons/hi";
import {RiMessengerFill} from "react-icons/ri";
import PostTaskWrapper from "../post-task/post-task-wrapper";
import {useLocation} from "@reach/router";
// import categories from "../../main-pages/services/categories.json";
import quikTaskLogo from "../../quickTaskAssets/Logos/Icon/SVG/QuickTaskicon.svg";
import {environment} from "../../environments/quickTaskEnvironemt"; // Importing the environment object
import sydeTaskerCategories from "../../content/services.json";
import quickTaskCategories from "../../content/servicesOfQuickTask.json";
import {project, isQuickTaskProject, title} from "../../utils/constant.urls";
  
const categories = isQuickTaskProject ? quickTaskCategories : sydeTaskerCategories;
  
const QuikTaskSecondaryColor = environment.secondaryColor;
const QuikTaskMainColor = environment.mainColor;
const logo = isQuickTaskProject ? quikTaskLogo : Sydetasker;
const QuikTaskAddress = environment.companyInfo.Address.completeAddress;

const NavBar = () => {
  const location = useLocation();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const smUpScrean = useMediaQuery(theme.breakpoints.up("sm"));
  //const xsDownScrean = useMediaQuery(theme.breakpoints.down("xs"));
  const xsDownScrean = useMediaQuery(theme.breakpoints.down("md"));
  const [isAuthenticated, setAuth] = useState(false);
  const [userData, setUserData] = useState<UserData>(null);
  const [dark, setDark] = useState<boolean>(false);
    
  
  const styles = {
    grow: {
      flexGrow: 1,
    },
    appBar: {
      backdropFilter: "blur(10px)",
      background: dark ? "rgba(0,0,0,0.7)" : "rgba(255, 255, 255, 0.92)",
      transition: "0.3s",
      height: "64px",
      boxShadow: "none",
      zIndex: 1200,
      boxSizing: "border-box",
    },
    toolBar: {
      minHeight: "64px",
      justifyContent: "center",
    },
    myAccountChip: {
      display: "flex",
      alignItems: "center",
      borderRadius: "40px",
      border: "1px solid rgb(240,240,240)",
      padding: "6px 8px",
      cursor: "pointer",
      transition: "0.3s",
      background: "rgb(247, 248, 251)",
      "&:hover": {
        background: "rgb(240,240,240)",
      },
    },
    loginButtons: {
      display: "flex",
      alignItems: "center",
      borderRadius: "40px",
      border: "1px solid rgb(240,240,240)",
      padding: "6px 8px",
      cursor: "pointer",
      transition: "0.3s",
      background: "rgba(247, 248, 251,0)",
      "&:hover": {
        background: "rgba(247, 248, 251,1)",
      },
    },
    loginButtonsPrimaryLight: {
      display: "flex",
      alignItems: "center",
      borderRadius: "40px",
      border: "1px solid rgb(240,240,240)",
      padding: "6px 8px",
      cursor: "pointer",
      transition: "0.3s",
      background: "rgba(108, 82, 255,0.06)",
      "&:hover": {
        background: "rgba(78, 62, 253,0.13)",
      },
    },
    loginButtonsPrimaryDark: {
      display: "flex",
      alignItems: "center",
      borderRadius: "40px",
      border: "1.5px solid rgba(255, 255, 255,0.6)",
      padding: "6px 8px",
      cursor: "pointer",
      transition: "0.3s",
      backdropFilter: "blur(18px)",
      background: "rgba(255, 255, 255,0.06)",
      "&:hover": {
        background: "rgba(255, 255, 255,0.13)",
      },
    },
    iconButton: {
      boxSizing: "unset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "40px",
      border: "1px solid rgb(240,240,240)",
      padding: "6px 8px",
      cursor: "pointer",
      width: "24px",
      height: "24px",
      transition: "0.3s",
      background: "rgb(247, 248, 251)",
      "&:hover": {
        background: "rgb(240,240,240)",
      },
    },
    iconNum: {
      boxSizing: "unset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "40px",
      border: "1px solid rgb(240,240,240)",
      padding: "6px 8px",
      cursor: "pointer",
      width: "24px",
      height: "24px",
      transition: "0.3s",
      // background: "rgb(247, 248, 251)",
    },
    dot: {
      height: "25px",
      width: "25px",
      backgroundColor: "#bbb",
      borderRadius: "50%",
      display: "inline-block",
    },
  };

  const getVerticalRule = (
    <span
      style={{
        borderLeft: "2px solid rgb(175,175,175, 0.15)",
        height: "24px",
        margin: "0 20px",
      }}
    />
  );

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        let doc = await firestore.collection("users").doc(user.uid).get();
        if (doc.exists && doc.data().project === project) {
          let data = doc.data();
          data.uid = user.uid;
          let privateDoc = await firestore
            .collection("users_stripe")
            .doc(user.uid)
            .get();
          if (privateDoc.exists && privateDoc.data().project === project) {
            data.phoneNumber = privateDoc.data()["phoneNumber"];
            data.birthDate = privateDoc.data()["birthDate"];
          }

          setUserData(new UserData(data));
          setAuth(true);
        }
      } else {
        setAuth(false);
        setUserData(null);
      }
    });
  }, []);

  const [scrollPast, setScrollPast] = useState(false);

  useEffect(() => {
    let color =
      ((location.pathname.includes("/services/") && location.pathname !== "/services/") ||
        location.pathname === "/support" ||
        location.pathname === "/support/") &&
      !scrollPast;
    setDark(color);
    window.addEventListener("scroll", () => {
      let v = 0.8;
      if (location.pathname === "/support" || location.pathname === "/support/") v = 0.5;
      if (window.scrollY > window.visualViewport.height * v - 64) {
        setScrollPast(true);
      } else {
        setScrollPast(false);
      }
    });
  }, [location.pathname]);
  const palette_Color = isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main;
  const loginButtonsPrimaryColor = dark
    ? {
        ...(styles.loginButtonsPrimaryDark as React.CSSProperties),
      }
    : {
        ...(styles.loginButtonsPrimaryLight as React.CSSProperties),
    };
  return (
    <div
      style={{
        ...(styles.grow as React.CSSProperties),
      }}
    >
      <AppBar
        position="fixed"
        style={{
          ...(styles.appBar as React.CSSProperties),
          borderBottom: `2px solid rgba(175,175,175,0.15)`,
          backdropFilter: dark ? " blur(42px) saturate(1.2)" : "blur(10px)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Container fixed={!smDown} maxWidth={"lg"}>
          <Toolbar
            style={{
              ...(styles.toolBar as React.CSSProperties),
            }}
            disableGutters={true}
          >
            <InlineLink variant={"primary"} to={"/"}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0 auto",
                  justifyContent: "flex-start",
                }}
              >
                {!smDown && isQuickTaskProject ? (
                  <></>
                ) : (
                  <img
                    loading={"lazy"}
                    src={logo}
                    height="28px"
                    alt={`${title} Official Logo`}
                    style={{filter: dark ? "grayscale(1) brightness(3)" : ""}}
                  />
                )}
                {!smDown && !isQuickTaskProject && (
                  <Text
                    variant={"h6"}
                    style={{
                      marginLeft: "10px",
                      transform: "translateY(-1px)",
                      color: dark ? "white" : "#66D1BA",
                      fontWeight: "bold",
                    }}
                    sydeLogo
                  >
                    Syde
                    <span
                      style={{
                        color: !dark ? palette_Color : "white",
                        display: "inline-block",
                      }}
                    >
                      tasker
                    </span>
                  </Text>
                )}
                {!smDown && isQuickTaskProject && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <img
                      loading={"lazy"}
                      src={logo}
                      alt={`${title} Official Logo`}
                      style={{
                        filter: dark ? "grayscale(1) brightness(3)" : "",
                        width: isQuickTaskProject ? "100px" : "inherit",
                        height: isQuickTaskProject ? "70px" : "inherit",
                        // marginTop: isQuickTaskProject ? "-7px" : "inherit",
                      }}
                    />
                    <Text
                      variant={"h6"}
                      style={{
                        marginLeft: "-20px",
                        transform: "translateY(-1px)",
                        color: dark ? "white" : "black",
                        fontWeight: "bold",
                      }}
                      sydeLogo
                    >
                      Quick
                      <span
                        style={{
                          color: !dark ? "black" : "white",
                          display: "inline-block",
                        }}
                      >
                        Task
                      </span>
                    </Text>
                  </div>
                )}
              </div>
            </InlineLink>
            {!xsDownScrean && (
              <>
                {getVerticalRule}
                {/* start */}
                {userData?.requestDeactivate === false || userData?.requestDeactivate === null ? (
                  <div>
                    {isAuthenticated ? (
                      <PostTaskWrapper>
                        <div
                          style={{
                            ...loginButtonsPrimaryColor,
                            backgroundColor: !dark ? palette_Color : "",
                          }}
                        >
                          <Text variant={"body2"} bold style={{cursor: "pointer"}}>
                            <span
                              style={{
                                color: "rgba(255, 255, 255, 0.92)",
                              }}
                            >
                              Post Task
                            </span>
                          </Text>
                        </div>
                      </PostTaskWrapper>
                    ) : (
                      <InlineLink variant={"primary"} to={"/login"}>
                        <div
                          style={{
                            ...loginButtonsPrimaryColor,
                            backgroundColor: !dark ? palette_Color : "",
                            padding: "0px 8px",
                          }}
                        >
                          <Text
                            variant={"body2"}
                            bold
                            style={{
                              color: "white",
                            }}
                          >
                            Post Task
                          </Text>
                        </div>
                      </InlineLink>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      ...loginButtonsPrimaryColor,
                      backgroundColor: !dark ? palette_Color : "",
                      padding: "0px 8px",
                    }}
                  >
                    <InlineLink variant={"primary"} to={"/login"}>
                      <Text
                        variant={"body2"}
                        bold
                        style={{
                          //margin: "0",
                          color: "white",
                        }}
                      >
                        Post Task
                      </Text>
                    </InlineLink>
                  </div>
                )}
                {/* end */}
                <div style={{width: "16px"}} />
                <Text variant={"body2"} bold>
                  <InlineLink
                    variant={"secondary"}
                    to={"/tasks"}
                    style={{color: dark ? "white" : "black"}}
                  >
                    Browse Tasks
                  </InlineLink>
                </Text>
                <div style={{width: "16px"}} />
                <div style={{display: "flex"}}>
                  <MenuWrapper
                    closeOnClick
                    placement={"bottom"}
                    items={
                      <Grid container style={{maxWidth: isQuickTaskProject ? "580px" : "470px"}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <InlineLink to={"/services"}>
                            <MenuListItem large>
                              <div>
                                <Text
                                  variant={"body2"}
                                  bold
                                  style={{color: dark ? "white" : "black"}}
                                >
                                  Services
                                </Text>
                                <Text
                                  variant={"caption"}
                                  regular
                                  style={{opacity: "0.5", maxWidth: "80%"}}
                                >
                                  Here are some of our most popular task categories...
                                </Text>
                              </div>
                            </MenuListItem>
                          </InlineLink>
                          <div style={{height: "8px"}} />
                        </Grid>

                        {categories.map((c) => {
                          return (
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} key={c.navTitle}>
                              <Text
                                variant={"body2"}
                                regular
                                style={{padding: "5px 12px", fontSize: "smaller"}}
                              >
                                <InlineLink
                                  to={"/services/" + c.location}
                                  variant={"primary"}
                                  style={{color: "black"}}
                                >
                                  {c.navTitle}{" "}
                                  <FiChevronRight
                                    style={{opacity: "0.5", marginLeft: "2px"}}
                                    size={12}
                                  />
                                </InlineLink>
                              </Text>
                            </Grid>
                          );
                        })}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div style={{height: "16px"}} />
                        </Grid>
                      </Grid>
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "64px",
                      }}
                    >
                      <Text variant={"body2"} bold>
                        <InlineLink variant={"secondary"} style={{color: dark ? "white" : "black"}}>
                          Services
                          <BsFillCaretDownFill
                            size={12}
                            style={{
                              marginLeft: "4px",
                              transform: "translateY(2px)",
                            }}
                            color={dark ? "white" : "black"}
                          />
                        </InlineLink>
                      </Text>
                    </div>
                  </MenuWrapper>
                </div>
                <div style={{width: "16px"}} />
                {isAuthenticated && (
                  <Text variant={"body2"} bold>
                    <InlineLink
                      variant={"secondary"}
                      to={"/my-tasks"}
                      style={{color: dark ? "white" : "black"}}
                    >
                      My Tasks
                    </InlineLink>
                  </Text>
                )}
              </>
            )}
            <div
              style={{
                ...(styles.grow as React.CSSProperties),
              }}
            />
            {!xsDownScrean && (
              <>
                <Text variant={"body2"} bold>
                  <InlineLink
                    variant={"secondary"}
                    to={"/support"}
                    style={{color: dark ? "white" : "black"}}
                  >
                    Need Help?
                  </InlineLink>
                </Text>
                <div style={{width: "20px"}} />
                {isAuthenticated ? (
                  <div style={{display: "flex"}}>
                    <div
                      style={{
                        height: "64px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Link to={`/profile${userData?.slug_title}`}>
                        <div
                          style={{
                            ...(styles.myAccountChip as React.CSSProperties),
                          }}
                        >
                          <Avatar
                            userId={userData?.slug_title}
                            name={userData.name}
                            profilePicture={userData.profilePicture}
                            size={22}
                          />
                          {!smDown && (
                            <Text
                              variant={"body2"}
                              bold
                              style={{marginLeft: "6px", marginRight: "8px"}}
                            >
                              {userData.firstName}
                            </Text>
                          )}
                        </div>
                      </Link>
                    </div>

                    <div style={{width: "12px"}} />
                    <MenuWrapper
                      items={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "10px 0px",
                            minWidth: "380px",
                            maxWidth: "380px",
                            minHeight: "240px",
                            maxHeight: "600px",
                            overflow: "overlay",
                            WebkitOverflowScrolling: "touch",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              padding: "0 12px",
                              boxSizing: "border-box",
                            }}
                          >
                            <Text variant={"body1"} black style={{marginBottom: "18px"}}>
                              Alerts
                            </Text>
                          </div>
                          <NotificationsList />
                        </div>
                      }
                      closeOnClick
                    >
                      <div
                        style={{
                          height: "64px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {userData?.unreadNotifications > 0 ? (
                          <div
                            style={{
                              ...(styles.iconButton as React.CSSProperties),
                            }}
                          >
                            <div
                              style={{
                                ...(styles.iconNum as React.CSSProperties),

                                height: "10px",
                                width: "10px",
                                fontSize: "11px",
                                color: "white",
                                marginBottom: "30px",
                                fontFamily: "HKGroteskMedium",
                                fontWeight: "bold",
                                background: palette_Color,
                              }}
                            >
                              {userData?.unreadNotifications > 0 &&
                              userData?.unreadNotifications < 9
                                ? userData?.unreadNotifications
                                : "+9"}
                            </div>
                            <div>
                              <HiBell
                                size={20}
                                style={{
                                  color: "rgb(40,40,40)",
                                  marginRight: "20px",
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              height: "64px",
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "10px",
                            }}
                          >
                            <div
                              style={{
                                ...(styles.iconButton as React.CSSProperties),
                              }}
                            >
                              <HiBell size={20} style={{color: "rgb(40,40,40)"}} />
                            </div>
                          </div>
                        )}
                      </div>
                    </MenuWrapper>
                    <div style={{width: "12px"}} />
                    {userData?.unreadMessages > 0 ? (
                      <div
                        style={{
                          height: "64px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <InlineLink to={"/messaging"}>
                          <div
                            style={{
                              ...(styles.iconButton as React.CSSProperties),
                            }}
                          >
                            {userData?.unreadMessages > 0 && (
                              <div
                                style={{
                                  ...(styles.iconNum as React.CSSProperties),
                                  height: "10px",
                                  width: "10px",
                                  fontSize: "11px",
                                  color: "white",
                                  marginBottom: "30px",
                                  fontFamily: "HKGroteskMedium",
                                  fontWeight: "bold",
                                  background: palette_Color,
                                }}
                              >
                                {userData?.unreadMessages > 0 && userData?.unreadMessages < 9
                                  ? userData?.unreadMessages
                                  : "+9"}
                              </div>
                            )}
                            <div>
                              <RiMessengerFill
                                size={18}
                                style={{
                                  color: "rgb(40,40,40)",
                                  marginRight: "20px",
                                }}
                              />
                            </div>
                          </div>
                        </InlineLink>
                      </div>
                    ) : (
                      <div
                        style={{
                          height: "64px",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "10px",
                        }}
                      >
                        <InlineLink to={"/messaging"}>
                          <div
                            style={{
                              ...(styles.iconButton as React.CSSProperties),
                            }}
                          >
                            <RiMessengerFill size={20} style={{color: "rgb(40,40,40)"}} />
                          </div>
                        </InlineLink>
                      </div>
                    )}

                    <div style={{width: "12px"}} />
                    {isAuthenticated && (
                      <MenuWrapper
                        disablePadding
                        items={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              padding: "14px 8px",
                              minWidth: "350px",
                              background: `linear-gradient(${theme.palette.background.default}, white)`,
                            }}
                          >
                            <Link to={`/profile${userData?.slug_title}`} style={{width: "100%"}}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  width: "100%",
                                  marginBottom: "12px",
                                }}
                              >
                                <Avatar
                                  userId={userData?.slug_title}
                                  name={userData.name}
                                  profilePicture={userData.profilePicture}
                                  size={48}
                                />
                                <Text variant={"body1"} black style={{marginTop: "6px"}}>
                                  {userData.name}
                                </Text>
                                <Text variant={"caption"} medium style={{color: "grey"}}>
                                  {userData.email}
                                </Text>
                              </div>
                            </Link>
                            <Link to={`/account`} style={{width: "100%"}}>
                              <MenuListItem
                                large
                                icon={
                                  <Icon
                                    fill={"rgb(101, 119, 134)"}
                                    name={"settings-2-outline"}
                                    size={"medium"}
                                  />
                                }
                              >
                                Account Settings
                              </MenuListItem>
                            </Link>
                            <MenuListItem
                              large
                              onClick={() => {
                                auth.signOut().then();
                              }}
                              icon={
                                <Icon
                                  fill={theme.palette.error.main}
                                  name={"power"}
                                  size={"medium"}
                                />
                              }
                              error
                            >
                              <div style={{color: "rgb(198, 53, 89)"}}>Logout</div>
                            </MenuListItem>
                          </div>
                        }
                      >
                        <div
                          style={{
                            height: "64px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              ...(styles.iconButton as React.CSSProperties),
                            }}
                          >
                            <BsFillCaretDownFill style={{color: "black"}} />
                          </div>
                        </div>
                      </MenuWrapper>
                    )}
                  </div>
                ) : (
                  <div style={{display: "flex", alignItems: "center"}}>
                    <Text variant={"body2"} bold>
                      <InlineLink
                        variant={"secondary"}
                        to={"/login"}
                        style={{color: dark ? "white" : "black"}}
                      >
                        {" "}
                        Sign In
                      </InlineLink>
                    </Text>
                    <div style={{width: "20px"}} />
                    <InlineLink variant={"primary"} to={"/register"} style={{color: "white"}}>
                      <div
                        style={{
                          ...(styles.loginButtonsPrimaryLight as React.CSSProperties),
                          backgroundColor: !dark ? palette_Color : "",
                        }}
                      >
                        <Text
                          selectable={false}
                          variant={"body2"}
                          bold
                          style={{
                            margin: "0 8px",
                            color: "white",
                          }}
                        >
                          Sign Up
                        </Text>
                      </div>
                    </InlineLink>
                  </div>
                )}
              </>
            )}
            {xsDownScrean && (
              <>
                {isAuthenticated ? (
                  <PostTaskWrapper>
                    <div
                      style={{
                        ...(styles.loginButtonsPrimaryDark as React.CSSProperties),
                      }}
                    >
                      <Text
                        selectable={false}
                        variant={"body2"}
                        bold
                        style={{
                          margin: "0 8px",
                          color: palette_Color,
                          width: "100%",
                        }}
                      >
                        Post a Task
                      </Text>
                    </div>
                  </PostTaskWrapper>
                ) : (
                  <InlineLink variant={"primary"} to={"/login"} style={{color: palette_Color}}>
                    <div
                      style={{
                        ...(styles.loginButtonsPrimaryLight as React.CSSProperties),
                      }}
                    >
                      <Text
                        selectable={false}
                        variant={"body2"}
                        bold
                        style={{
                          margin: "0 8px",
                          color: palette_Color,
                        }}
                      >
                        Post a Task
                      </Text>
                    </div>
                  </InlineLink>
                )}

                <div style={{width: "20px"}} />

                {!isAuthenticated && (
                  <>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <Text variant={"body2"} bold>
                        <InlineLink
                          variant={"secondary"}
                          to={"/login"}
                          style={{color: dark ? "white" : "black"}}
                        >
                          Sign In
                        </InlineLink>
                      </Text>
                      <div style={{width: "20px"}} />
                      <InlineLink variant={"primary"} to={"/register"} style={{color: "white"}}>
                        <div
                          style={{
                            ...loginButtonsPrimaryColor,
                            backgroundColor: !dark ? palette_Color : "",
                          }}
                        >
                          <Text selectable={false} variant={"body2"} bold style={{color: "white"}}>
                            Sign Up
                          </Text>
                        </div>
                      </InlineLink>
                    </div>
                  </>
                )}
                {isAuthenticated && (
                  <>
                    <MenuWrapper
                      items={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "10px 0px",
                            minWidth: "380px",
                            maxWidth: "380px",
                            minHeight: "240px",
                            maxHeight: "600px",
                            overflow: "overlay",
                            WebkitOverflowScrolling: "touch",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              padding: "0 12px",
                              boxSizing: "border-box",
                            }}
                          >
                            <Text variant={"body1"} black style={{marginBottom: "18px"}}>
                              Alerts
                            </Text>
                          </div>
                          <NotificationsList />
                        </div>
                      }
                      closeOnClick
                    >
                      <div
                        style={{
                          height: "64px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            ...(styles.iconButton as React.CSSProperties),
                          }}
                        >
                          <HiBell size={20} style={{color: "rgb(40,40,40)"}} />
                        </div>
                      </div>
                    </MenuWrapper>
                    <div
                      style={{
                        height: "64px",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <InlineLink to={"/messaging"}>
                        <div
                          style={{
                            ...(styles.iconButton as React.CSSProperties),
                          }}
                        >
                          <RiMessengerFill size={20} style={{color: "rgb(40,40,40)"}} />
                        </div>
                      </InlineLink>
                    </div>
                  </>
                )}
                <div style={{width: "20px"}} />
                <MenuWrapper
                  disablePadding
                  items={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "14px 8px",
                        width: "calc(100vw - 32px)",
                        background: `linear-gradient(${theme.palette.background.default}, white)`,
                      }}
                    >
                      {isAuthenticated && (
                        <>
                          <Link to={`/profile${userData?.slug_title}`} style={{width: "100%"}}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "100%",
                                marginBottom: "12px",
                              }}
                            >
                              <Avatar
                                userId={userData?.slug_title}
                                name={userData?.name}
                                profilePicture={userData?.profilePicture}
                                size={48}
                              />
                              <Text variant={"body1"} black style={{marginTop: "6px"}}>
                                {userData?.name}
                              </Text>
                              <Text variant={"caption"} medium style={{color: "grey"}}>
                                {userData?.email}
                              </Text>
                            </div>
                          </Link>
                          <Link to={"/account"} style={{width: "100%"}}>
                            <MenuListItem
                              large
                              icon={
                                <Icon
                                  fill={"rgb(101, 119, 134)"}
                                  name={"settings-2-outline"}
                                  size={"medium"}
                                />
                              }
                            >
                              Account Settings
                            </MenuListItem>
                          </Link>
                        </>
                      )}
                      <Link to={"/tasks"} style={{width: "100%"}}>
                        <MenuListItem
                          large
                          icon={
                            <Icon
                              fill={"rgb(101, 119, 134)"}
                              name={"search-outline"}
                              size={"medium"}
                            />
                          }
                        >
                          Browse Tasks
                        </MenuListItem>
                      </Link>

                      {isAuthenticated && (
                        <>
                          <Link to={"/my-tasks/poster"} style={{width: "100%"}}>
                            <MenuListItem
                              large
                              icon={
                                <Icon
                                  fill={"rgb(101, 119, 134)"}
                                  name={"file-text-outline"}
                                  size={"medium"}
                                />
                              }
                            >
                              My Tasks
                            </MenuListItem>
                          </Link>
                          <MenuListItem
                            large
                            onClick={() => {
                              auth.signOut().then();
                            }}
                            icon={
                              <Icon
                                fill={theme.palette.error.main}
                                name={"power"}
                                size={"medium"}
                              />
                            }
                            error
                          >
                            Logout
                          </MenuListItem>
                        </>
                      )}
                    </div>
                  }
                >
                  <div
                    style={{
                      height: "64px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        ...(styles.iconButton as React.CSSProperties),
                      }}
                    >
                      <HiMenuAlt4 style={{color: "black"}} />
                    </div>
                  </div>
                </MenuWrapper>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default NavBar;
