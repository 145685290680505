import React, {useEffect, useState,useRef} from "react";
import CircularProgressBar from "../progress-bars/circular-progress-bar";
import Fade from "@mui/material/Fade";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import {notificationsCollection} from "../../utils/firebase.utils";
import {Notification} from "../../models/notification";
import Text from "../data-display/text";
import NotificationCard from "./notification-card";
import {project} from "../../utils/constant.urls";

const NotificationsList = () => {
  const [loading, setLoading] = useState(true);
  const [notificationsData, setNotificationsData] = useState<Notification[]>([]);
  const {userData, isAuthenticated} = useSelector((state: RootState) => state.userData);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const sentinel = useRef<HTMLDivElement>(null);
  const [lastNotification, setNotification] = useState(null);
  const getUserNotifications = async () => {
    setLoading(true);
    await notificationsCollection(userData?.uid)
      .limit(10)
      .orderBy("createAt", "desc")
      .get()
      .then((snapshot) => {
        if (snapshot.size > 0) {
          let array: Notification[] = [];
          let lastdoc = null;
          snapshot.forEach((doc) => {
            let data = doc.data();
            //if (data && data.project === project) {
            if (data.type !== "new_message" && data.type !== "reminder_alert_task") {
              array.push(new Notification(data));
            }
            lastdoc = doc;
            //}
          });
          array.sort((a, b) => {
            if (a.createAt < b.createAt) {
              return 1;
            } else {
              return -1;
            }
          });

          setNotification(lastdoc);
          setNotificationsData(array);
          setHasMore(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };
  useEffect(() => {
    if (isAuthenticated) {
      getUserNotifications();
    } else {
      setNotificationsData([]);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let observer = new IntersectionObserver(onSentinelIntersection);
    if (sentinel.current) observer.observe(sentinel.current);
    return function cleanup() {
      observer.disconnect();
    };
  });

  const onSentinelIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (hasMore) {
          let lastdoc = null;
          notificationsCollection(userData?.uid)
            .orderBy("createAt", "desc")
            .limit(10)
            .startAfter(lastNotification)
            .get()
            .then((snapshot) => {
              if (snapshot.size > 0) {
                let array = [...notificationsData];
                snapshot.forEach((doc) => {
                  let data = doc.data();
                  //if (data && data.project === project) {
                  if (data.type !== "new_message" && data.type !== "reminder_alert_task") {
                    array.push(new Notification(data));
                  }
                  //  }
                  lastdoc = doc;
                });

                array.sort((a, b) => {
                  if (a.createAt < b.createAt) {
                    return 1;
                  } else {
                    return -1;
                  }
                });
                setNotification(lastdoc);
                setNotificationsData(array);
                setHasMore(true);
              } else {
                setHasMore(false);
              }
            });
        }
      }
    });
  };

  return (
    <>
      <Fade in={loading} mountOnEnter unmountOnExit>
        <div style={{position: "absolute", top: "64px"}}>
          <CircularProgressBar />
        </div>
      </Fade>

      <Fade
        in={!loading && notificationsData.length > 0}
        style={{transitionDelay: "0.3s"}}
        mountOnEnter
        unmountOnExit
      >
        <div style={{display: "flex", flexDirection: "column"}}>
          {notificationsData.map((n) => (
            <div style={{marginBottom: "6px"}} key={n.uid}>
              <NotificationCard notification={n} />
            </div>
          ))}
          <div ref={sentinel} />
        </div>
      </Fade>

      <Fade
        in={!loading && notificationsData.length === 0}
        style={{transitionDelay: "0.3s"}}
        mountOnEnter
        unmountOnExit
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "18px 0",
          }}
        >
          <img
            loading={"lazy"}
            src={
              "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/images%2Fnotifications_empty.png?alt=media&token=80e261c6-4034-46bb-bf8d-739301ae029d"
            }
            alt={"You're all caught up!"}
            width={100}
          />
          <div style={{height: "16px"}} />
          <Text style={{color: "black"}} medium variant={"caption"}>
            You're all caught up!
          </Text>
        </div>
      </Fade>
    </>
  );
};

export default NotificationsList