import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const getFirebaseConfig = () => {
  switch (process.env.GATSBY_APP_ENV) {
    case "production":
      return {
        apiKey: "AIzaSyBwikRgeisiemSFd8-mMb6aLAwqVIhH8mg",
        authDomain: "sydetasker.com",
        databaseURL: "https://sydework-api.firebaseio.com",
        projectId: "sydework-api",
        storageBucket: "sydework-api.appspot.com",
        messagingSenderId: "681416980342",
        appId: "1:681416980342:web:04f92e0fb9fbee09",
      };
    case "development":
    case "quicktask-dev":
      return {
        apiKey: "AIzaSyBzaVRTzuHupOUHjdDHZfYPJZlhcUyDfBY",
        authDomain: "sydework-dev.firebaseapp.com",
        databaseURL: "https://sydework-dev.firebaseio.com",
        projectId: "sydework-dev",
        storageBucket: "sydework-dev.appspot.com",
        messagingSenderId: "460196080444",
        appId: "1:460196080444:web:0fd2f5851521a967664c39",
        measurementId: "G-PS44QYP4P5",
      };
    case "quicktask-prod":
      return {
        apiKey: "AIzaSyDbFyTxikCeFUuMZFIP3JzrrukHCsD2kos",
        authDomain: "quicktask-prod-e2bc9.firebaseapp.com",
        projectId: "quicktask-prod-e2bc9",
        storageBucket: "quicktask-prod-e2bc9.appspot.com",
        messagingSenderId: "889560558812",
        appId: "1:889560558812:web:6b6f2b80fc5caef11fd819",
        measurementId: "G-51H0HB6Q7M"
      };
    default:
      throw new Error('No Firebase config found for the current environment.');
  }
};

const firebaseConfig = getFirebaseConfig();

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
// Auth Providers
export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
export const AppleProvider = new firebase.auth.OAuthProvider('apple.com');
export const FacebookProvider = new firebase.auth.FacebookAuthProvider();

// Auth Functions
export const RegisterWithEmailAndPassword = (email, password) => auth.createUserWithEmailAndPassword(email, password);
export const logout = () => auth.signOut();
export const SignInWithGoogle = () => {
  return auth.signInWithPopup(GoogleProvider);
};

export const SignInWithApple = () => {
  return auth.signInWithPopup(AppleProvider);
};

export const SignInWithFacebook = () => {
  return auth.signInWithPopup(FacebookProvider);
};

// Firestore Collections
export const users = firestore.collection('users');
export const privateUsers = firestore.collection('users_stripe');
export const tasks = firestore.collection('tasks');
export const AdminMessage = firestore.collection('messaging_with_admin');
export const notificationsCollection = (uid) => firestore.collection('users').doc(uid).collection('notifications');

// Storage References
export const myTaskAttachments = (uid) => storage.ref(`/taskAttachments/${uid}`);
export const myPortfolio = (uid) => storage.ref(`/portfolioImages/${uid}`);




export default firebase;
