import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import { RootState } from "../../redux/index.reducers";
import { isIOS } from 'react-device-detect';
import StarRating from "../reviews-ratings/star-rating";
import {styled} from "@mui/material/styles";
import {Close} from "@material-ui/icons";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/system";
import SecondaryButton from "../buttons/secondary-button";
import Text from "../data-display/text"
import SydetaskerIcon from "../../images/sydework.png";
import {navigate} from 'gatsby';
import { environment } from "../../environments/quickTaskEnvironemt"; // Importing the environment object
import QuikTasklogo from '../../quickTaskAssets/Logos/Icon/PNG/QuickTaskicon.png'
import {title, isQuickTaskProject, GooglePlayLink, appStoreLink} from "../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;

const AppDialog = () => {
  const [currentPage, setPage] = useState<number>(0);
  const {isAuthenticated} = useSelector((state: RootState) => state.userData);
  const theme = useTheme();
  const Offset = styled("div")(({theme}) => theme.mixins.toolbar);
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const check = typeof window !== "undefined" && window.localStorage.getItem("userClicked");
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [link, setLink] = useState("");

  const click = () => {
    typeof window !== "undefined" && window.localStorage.setItem("userClicked", "true");
  };

  useEffect(() => {
    if (isIOS) {
      setLink(appStoreLink);
    } else {
      setLink(GooglePlayLink);
    }
  }, []);

  return (
    <div>
      {typeof window !== "undefined" &&
        window.location.pathname !== "/" &&
        check !== "true" &&
        xsDown && (
          <div
            style={{
              position: "fixed",
              bottom: "16px",
              right: "8px",
              left: "8px",
              zIndex: 1000,
              height: "120px",
              background: "white",
              boxShadow: "0 -8px 24px -4px rgba(0,0,0,0.08)",
              borderRadius: "14px",
              width: "calc(100% - 16px)",
            }}
          >
            <div style={{height: "20px"}} />
            <>
              <div
                style={{
                  height: "100%",
                  padding: "12px 16px",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <img
                  src={isQuickTaskProject ? QuikTasklogo : SydetaskerIcon}
                  height="48px"
                  alt={`${title} Official Logo`}
                  style={{flexShrink: 0}}
                />

                <div style={{marginLeft: "8px", flex: 1}}>
                  <span style={{marginTop: "5px"}}>
                    <Text
                      variant="body2"
                      style={{color: "black", width: " 82px", marginRight: "20px"}}
                    >
                      {" "}
                      {title}
                    </Text>
                    <StarRating totalAverage={5} />
                  </span>
                </div>

                <SecondaryButton
                  onClick={() => navigate(link)}
                  background={isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main}
                  textColor={"white"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "155px",
                    marginRight: "20px",
                  }}
                >
                  Get App
                </SecondaryButton>

                <div
                  onClick={() => click()}
                  style={{position: "absolute", right: "6px", top: "6px"}}
                >
                  <Close
                    style={{
                      opacity: 1,
                      transform: `rotate( "0deg")`,
                      transition: "0.3s",
                      marginLeft: "30px",
                      color: isQuickTaskProject ? QuikTaskMainColor : "rgb(63, 81, 181)",
                      marginBottom: "80px",
                    }}
                  />
                </div>
              </div>
            </>
            <Offset />
          </div>
        )}
    </div>
  );
};

export default AppDialog;