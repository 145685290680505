import React, { useEffect, useState } from "react";
import { categories, getCategoryImage } from "../../../utils/category-icons";
import {Grid} from "@mui/material";
import {useMediaQuery} from "@mui/material";
import Text from "../../../components/data-display/text";
import { Check } from "react-feather";
import {useTheme} from "@mui/system";
import { PostTaskProps } from "../post-task-wrapper";
import FormErrorText from "../../data-display/form-error-text";
import DialogBottomButton from "../../../components/dialogs/dialog-bottom-button";
import { environment } from "../../../environments/quickTaskEnvironemt";
import { isQuickTaskProject } from "../../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;
const styles = {
  categoryCardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
  },
  categoryIcon: {
    background: "white",
    borderRadius: "14px",
    boxShadow: "0 4px 12px 0 rgba(0,0,0,0.02)",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    transition: "0.4s",
    boxSizing: "border-box",
    transitionTimingFunction: "cubic-bezier(0.165, 0.84, 0.44, 1.0)",
    "&:hover": {
      transform: "scale(0.9)",
    },
  },
  categoryIconSelected: {
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "0.3s",
  },
  categoryIconCheckmark: {
    background: "white",
    width: "28px",
    height: "28px",
    borderRadius: "50%",
    transition: "0.4s",
    transitionTimingFunction: "cubic-bezier(0.165, 0.84, 0.44, 1.0)",
  },
};

const SelectCategory = ({task, updateTask, onContinue}: PostTaskProps) => {
  const theme = useTheme();
  const palette_Color = isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main;
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [selected, setSelected] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setSelected(task.category ?? "");
  }, [task]);

  const goNext = () => {
    if (updateTask) {
      if (selected) {
        task.category = selected;
        updateTask(task);
        onContinue();
      } else {
        setError("Please select a suitable category for your task.");
      }
    }
  };

  return (
    <div style={{paddingTop: "16px", height: "620px"}}>
      <Text variant={"body2"} style={{color: palette_Color}} medium>
        Select a Category
      </Text>
      <FormErrorText error={error} showError={true} style={{marginTop: "14px"}} />
      <Grid container style={{padding: "10px 0"}} spacing={xsDown ? 3 : 4}>
        {categories().map((c, index) => {
          return (
            <Grid item xs={4} sm={3} key={index}>
              <div
                style={{
                  ...(styles.categoryCardContainer as React.CSSProperties),
                  margin: xsDown ? "0 16px" : "",
                }}
                onClick={() => {
                  setSelected(c);
                  setError("");
                }}
              >
                <div
                  style={{
                    ...(styles.categoryIcon as React.CSSProperties),
                    border: error
                      ? "2px solid rgba(235, 80, 60, 0.3)"
                      : isQuickTaskProject
                      ? "0px solid white"
                      : "2px solid white",
                  }}
                >
                  <div
                    style={{
                      ...(styles.categoryIconSelected as React.CSSProperties),
                      background: selected === c ? palette_Color : "rgba(78, 62, 253, 0)",
                    }}
                  >
                    <div
                      style={{
                        ...(styles.categoryIconCheckmark as React.CSSProperties),
                        transform: selected === c ? "scale(1.0)" : "scale(0.5)",
                        opacity: selected === c ? 1 : 0,
                      }}
                    >
                      <Check style={{color: palette_Color, margin: "4px"}} size={22} />
                    </div>
                  </div>
                  <img
                    style={{
                      padding: isQuickTaskProject ? "10px" : "18px",
                      width: "100%",
                      height: "100%",
                      boxSizing: "border-box",
                    }}
                    src={getCategoryImage(c).toString()}
                    alt={c}
                  />
                </div>
                <Text
                  variant={"caption"}
                  selectable={false}
                  medium
                  style={{
                    textTransform: "capitalize",
                    textAlign: isQuickTaskProject ? "left" : "center",
                    marginTop: "8px",
                    width: "90px",
                  }}
                >
                  {c.toString().replaceAll("-", " ")}
                </Text>
              </div>
            </Grid>
          );
        })}
      </Grid>
      <DialogBottomButton style={{marginTop: "100px"}} type={"submit"} onClick={goNext}>
        Continue
      </DialogBottomButton>
    </div>
  );
};

export default SelectCategory;
