import { project } from "../utils/constant.urls";
import Address from "./address";

export class BirthDate {
  day: number | null = null;
  month: number | null = null;
  year: number | null = null;

  get date() {
    if (this.day !== null && this.month !== null && this.year !== null)
      return new Date(
        `${this.month.toString()}-${this.day.toString()}-${this.year.toString()}`
      );
    else return null;
  }

  constructor(data?: { [field: string]: any }) {
    Object.assign(this, data);
  }

  toJson(): BirthDate {
    return Object.assign({}, this);
  }
}

export class Geoloc {
  lat: number | null = null;
  lng: number | null = null;

  constructor(data?: { [field: string]: any }) {
    this.lat = data?.lat ?? null;
    this.lng = data?.lng ?? null;
  }

  toJson(): Geoloc {
    return Object.assign({}, this);
  }
}

export class AssignedWorker {
  uid: string | null = null;
  name: string | null = null;
  project: string | null = null;
  profilePicture: string | null = null;
  reviewTotalAverage: number | null = null;
  taskPrice: string | null = null;
  bookingFees: string | null = null;
  totalPaid: string | null = null;
  serviceFees: string | null = null;
  totalTransferred: string | null = null;
  discountPrice: number | null = null;

  constructor(data?: {[field: string]: any}) {
    this.uid = data?.uid ?? null;
    this.project =project ?? null;
    this.name = data?.name ?? null;
    this.profilePicture = data?.profilePicture ?? null;
    this.reviewTotalAverage = data?.reviewTotalAverage
      ? parseInt(data.reviewTotalAverage.toString())
      : null;
    this.taskPrice = data?.taskPrice ?? null;
    this.bookingFees = data?.bookingFees ?? null;
    this.totalPaid = data?.totalPaid ?? null;
    this.serviceFees = data?.serviceFees ?? null;
    this.totalTransferred = data?.totalTransferred ?? null;
    this.discountPrice = data?.discountPrice ? data.discountPrice.toString() : null;
  }

  toJson(): AssignedWorker {
    return Object.assign({}, this);
  }
}

export class TaskOwner {
  name: string | null = null;
  project: string | null = null;
  profilePicture: string | null = null;

  constructor(data?: {[field: string]: any}) {
    this.name = data?.name ?? "";
    this.project =project ?? "";
    this.profilePicture = data?.profilePicture ?? null;
  }

  toJson(): TaskOwner {
    return Object.assign({}, this);
  }
}
export default class DisputeData {
  uid: string | null = "";
  project: string | null = "";
  type: string | null = "";
  reason: string | null = "";
  disputeDate: Date = null;

  constructor(data?: {[field: string]: any}) {
    this.uid = data?.uid ?? null;
    this.project = project ?? null;
    this.type = data?.type ?? null;
    this.reason = data?.reason ?? null;
    this.disputeDate = data?.disputeDate
      ? typeof data?.disputeDate === "string"
        ? new Date(data.disputeDate)
        : data.disputeDate instanceof Date
        ? data?.disputeDate
        : new Date(data?.disputeDate)
      : null;
  }

  toJson(): DisputeData {
    return Object.assign({}, this);
  }
}

export class Task {
  id: string | null = null;
  project: string | null = null;
  dateType: string | null = null;
  slug_title: string | null = null;
  title: string | null = null;
  description: string | null = null;
  category: string | null = null;
  isMultipleTasker: boolean | null = false;
  taskerRequired: number | null = 1;
  isRemote: boolean | null = false;
  isAssigned: boolean | null = false;
  isComplete: boolean | null = false;
  isCancelled: boolean | null = false;
  isHourly: boolean | null = false;
  isDispute: boolean | null = false;
  hours: number | null = 0;
  budgetByHour: string | null = null;
  workerHasReviewed: boolean | null = false;
  taskOwnerHasReviewed: boolean | null = false;
  requestPayment: boolean | null = false;
  city: string | null = null;
  _geoloc: Geoloc | null = null;
  dateToStart: Date = null;
  dateToStartUnix: Date = null;
  completeDate: Date = null;
  timings: string[] | null = [];
  requirements: string[] | null = [];
  attachments: string[] | null = [];
  offerUIDs: string[] | null = [];
  budget: string | null = null;
  offers: number | null = 0;
  comments: number | null = 0;
  priority: number | null = 0;
  lowestOffer: string | null = null;
  assignedWorker: AssignedWorker | null = null;
  taskOwner: TaskOwner | null = null;
  uid: string | null = null;
  paymentIntent: string | null = null;
  address: Address | null = null;
  disputeData: DisputeData | null = null;
  createAt: Date = null;
  invitedTaskers: string[] | null = [];

  constructor(data?: {[field: string]: any}) {
    this.id = data?.id ?? null;
    this.dateType = data?.dateType ?? null;
    this.project = project ?? null;
    this.slug_title = data?.slug_title ?? null;
    this.title = data?.title ?? null;
    this.description = data?.description ?? null;
    this.category = data?.category ?? null;
    this.isMultipleTasker = data?.isMultipleTasker ?? false;
    this.taskerRequired = data?.taskerRequired ?? 1;
    this.isRemote = data?.isRemote ?? false;
    this.isAssigned = data?.isAssigned ?? false;
    this.isComplete = data?.isComplete ?? false;
    this.isCancelled = data?.isCancelled ?? false;
    this.isDispute = data?.isDispute ?? false;
    this.workerHasReviewed = data?.workerHasReviewed ?? false;
    this.taskOwnerHasReviewed = data?.taskOwnerHasReviewed ?? false;
    this.requestPayment = data?.requestPayment ?? false;
    this.city = data?.city ?? null;
    this._geoloc = data?._geoloc != null ? new Geoloc(data._geoloc) : null;
    this.dateToStart = data?.dateToStart
      ? typeof data?.dateToStart === "string"
        ? new Date(data.dateToStart)
        : data.dateToStart instanceof Date
        ? data.dateToStart
        : new Date(data.dateToStart.toDate().toString())
      : null;
    this.timings = data?.timings != null ? data.timings : [];
    if (data?.dateToStartUnix != null)
      this.dateToStartUnix = new Date(data?.dateToStartUnix * 1000);

    this.completeDate = data?.completeDate
      ? typeof data?.completeDate === "string"
        ? new Date(data.completeDate)
        : data.completeDate instanceof Date
        ? data.completeDate
        : new Date(data.completeDate.toDate().toString())
      : null;
    this.requirements = data?.requirements != null ? data.requirements : [];
    this.attachments = data?.attachments != null ? data.attachments : [];
    this.offerUIDs = data?.offerUIDs != null ? data.offerUIDs : [];
    this.budget = data?.budget ?? null;
    this.offers = data?.offers ?? 0;
    this.comments = data?.comments ?? 0;
    this.priority = data?.priority ?? 0;
    this.lowestOffer = data?.lowestOffer ?? "";
    this.assignedWorker =
      data?.assignedWorker != null ? new AssignedWorker(data.assignedWorker) : null;
    this.taskOwner = data?.taskOwner != null ? new TaskOwner(data.taskOwner) : null;
    this.uid = data?.uid ?? null;
    this.paymentIntent = data?.paymentIntent ?? null;
    this.createAt = data?.createAt
      ? typeof data?.createAt === "string"
        ? new Date(data.createAt)
        : data.createAt instanceof Date
        ? data.createAt
        : new Date(data.createAt.toDate().toString())
      : null;
    this.address = data?.address != null ? new Address(data.address) : null;
    this.disputeData = data?.disputeData != null ? new DisputeData(data.disputeData) : null;
    this.isHourly = data?.isHourly ?? false;
    this.hours = data?.hours ?? 0;
    this.budgetByHour = data?.budgetByHour ?? null;
    this.invitedTaskers = data?.invitedTaskers != null ? data.invitedTaskers : [];
  }

  get isExpired(): boolean {
    return (
      !this.isCancelled &&
      !this.isAssigned &&
      !this.isComplete &&
      (this.dateToStart as Date) < new Date()
    );
  }

  get isOpen(): boolean {
    return !this.isCancelled && !this.isAssigned && !this.isComplete && !this.isExpired;
  }

  get isAssignedOnly(): boolean {
    return !this.isCancelled && this.isAssigned && !this.isComplete;
  }

  toJson(): Task {
    return Object.assign({}, this);
  }
}
