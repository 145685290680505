import React, { useEffect, useState } from "react";
import { Dispatch } from "redux"; // Import Dispatch from Redux

import Text from "../data-display/text";
import {useTheme} from "@mui/system";
import {auth} from "../../utils/firebase.utils";
import FormErrorText from "../data-display/form-error-text";
import Form from "../forms/form-wrapper";
import FormInput from "../forms/form-input";
import DialogWrapper from "../dialogs/dialog-wrapper";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import {Container, Grid} from "@mui/material";
import moment from "moment";
import {UserActionTypes} from "../../redux/types/user-type";
import DialogBottomButton from "../dialogs/dialog-bottom-button";
import {users} from "../../utils/firebase.utils";
import {useMediaQuery} from "@mui/material";
import { updateUser } from "../../redux/actions/user-actions";
import { navigate } from "gatsby";
import {environment} from "../../environments/quickTaskEnvironemt";
import {isQuickTaskProject, title} from "../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;
  

const DeactivateAccount = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch<Dispatch<UserActionTypes>>();
  const {userData, isVerified, isAuthenticated} = useSelector((state: RootState) => state.userData);
  const [open, setOpen] = useState(false);

  const ReactiveAccount = async () => {
    return new Promise((resolve, reject) => {
      if (userData?.requestDeactivate === true) {
        users
          .doc(userData.uid)
          .set({requestDeactivate: false}, {merge: true})
          .then(() => {
            userData.acceptTaskerRules = true;
            dispatch(updateUser(userData));
            window.location.reload();
            navigate(`/profile`);
          })
          .catch(() => {
            reject("Something went wrong on our side!");
          });
      } else {
        reject("");
      }
    });
  };

  return (
    <div style={style}>
      {isAuthenticated && userData?.requestDeactivate === true ? (
        <Container
          maxWidth={"lg"}
          style={{
            // padding: smDown ? "0 5%" : mdDown ? "0 10%" : "0 15%",
            paddingTop: "200px",
          }}
        >
          <Grid container>
            <Grid item lg={12}>
              <Text
                variant={"h1"}
                style={{
                  fontWeight: 400,
                  color: isQuickTaskProject ? QuikTaskMainColor : "rgb(78, 62, 253)",
                }}
              >
                Reactivate your account ?
              </Text>
            </Grid>
            <Grid item lg={8} style={{marginTop: "30px"}}>
              <Text
                variant={"body1"}
                style={{
                  fontWeight: 400,
                  color: "gray",
                }}
              >
                You deactivated your account on{" "}
                {moment(userData?.requestDeactivateDate).format("DD MMM. YYYY")}. On
                {moment(userData?.deleteUserDate).format("DD MMM. YYYY")}, it will no longer be
                possible for you to restore your {title} account if it was accidentally or
                wrongfully deactivated. By click "Yes, reactivate", you will halt the deactivation
                process and reactive your account.
              </Text>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-start" style={{marginTop: "40px"}}>
            <Grid lg={3}>
              <Text variant={"body2"} bold style={{cursor: "pointer"}}>
                <span
                  style={{
                    margin: "0 8px",
                    color: "rgba(255, 255, 255, 0.92)",
                    backgroundColor: isQuickTaskProject
                      ? QuikTaskMainColor
                      : theme.palette.primary.main,
                    padding: "10px 32px 12px 32px ",
                    borderRadius: "20px",
                  }}
                  onClick={ReactiveAccount}
                >
                  Yes, reactivate
                </span>
              </Text>
            </Grid>
            <Grid lg={2}>
              {" "}
              <Text variant={"body2"} bold style={{cursor: "pointer"}}>
                <span
                  style={{
                    margin: "0 8px",
                    color: "rgb(198, 53, 89)",
                    border: "1px solid lightgray",
                    padding: "7px 38px 8px 38px",
                    borderRadius: "25px",
                  }}
                  onClick={() => {
                    auth.signOut().then();
                  }}
                >
                  {" "}
                  Logout
                </span>
              </Text>
            </Grid>
          </Grid>
        </Container>
      ) : (
        children
      )}
    </div>
  );
};

export default DeactivateAccount;
