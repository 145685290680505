import QuickCleaningImg from "../quickTaskAssets/category_Icons/cleaning-service.png";
import QuickDogWalkingImg from "../quickTaskAssets/category_Icons/dog-walking.png";
import QuickElectricianImg from "../quickTaskAssets/category_Icons/electrician.png";
import QuickGamerImg from "../quickTaskAssets/category_Icons/gamer.png";
import QuickGardenerImg from "../quickTaskAssets/category_Icons/gardener.png";
import QuickPaintImg from "../quickTaskAssets/category_Icons/painter.png";
import QuickmotherImg from "../quickTaskAssets/category_Icons/mother.png";
import QuickplumberImg from "../quickTaskAssets/category_Icons/plumber.png";
import QuickDeliveryImg from "../quickTaskAssets/category_Icons/driver.png";
import QuickElectricityImg from "../quickTaskAssets/category_Icons/electricity2.png";
import QuickRemovalsImg from "../quickTaskAssets/category_Icons/wholesale.png";
import QuickHandymanImg from "../quickTaskAssets/category_Icons/handyman.png";
import QuickAnyThing from "../quickTaskAssets/category_Icons/antThing_lightbulb.png";

import {isQuickTaskProject} from "./constant.urls";
export const getCategoryImage = (category: string) => {
  const customImg =
    "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/categories%2Fcustom.png?alt=media&token=10f8c40e-cd36-4161-ae31-03b28d5e910e";
  if (category)
    if (!isQuickTaskProject) {
      switch (category.toLowerCase()) {
        case "cleaning":
          return "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/categories%2Fcleaning.png?alt=media&token=6cac7948-4b16-4cc0-8afd-5ce16220e1c1";
        case "assembly":
          return "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/categories%2Fassembly.png?alt=media&token=92f4a356-b726-4f5c-bd8b-46bcd2b25b46";
        case "handyman":
          return "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/categories%2Fhandyman.png?alt=media&token=854a0613-8c20-411d-827d-4becdfd7334c";
        case "delivery":
          return "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/categories%2Fdelivery.png?alt=media&token=49c0f3eb-b34f-425b-8f31-2b5c3102db98";
        case "yardwork":
          return "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/categories%2Fgardening.png?alt=media&token=ab51a8e1-e3d2-455e-a92a-e1b9fe5666ba";
        case "admin":
          return "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/categories%2Fadmin.png?alt=media&token=01a009a6-14bf-40fb-ba7b-6db5afa8387f";
        case "hauling":
          return "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/categories%2Fremovalists.png?alt=media&token=735c8f54-c7a5-4a86-afe1-3de0ef0796f4";
        case "computer it":
        case "computer-it":
        case "computerit":
          return "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/categories%2Fcomputer.png?alt=media&token=d3356657-26bd-4538-8461-666df0186d4a";
        case "photography":
          return "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/categories%2Fphotography.png?alt=media&token=9475eec5-d646-4b0b-9864-3fdebb65f073";
        case "custom":
          return customImg;
        case "painting":
          return "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/categories%2Fpainting.png?alt=media&token=662f6cc2-5050-4a2c-8798-f5704061756e";
        case "snow-removal":
          return "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/categories%2Fsnow.png?alt=media&token=1ab88345-06a3-4640-aec7-7caed0563e07";
        case "lifting":
          return "https://ik.imagekit.io/wffo0jsx3/syde-dev/o/categories%2Flifting.png?alt=media&token=592081fd-263e-48ef-9c01-a66b384408a3";
        default:
          return customImg;
      }
    } else {
      switch (category.toLowerCase()) {
        case "cleaning and ironing services":
        case "cleaning-and-ironing-services":
          return QuickCleaningImg;
        case "painting & decorating":
        case "painting-&-decorating":
          return QuickPaintImg;
        case "IT and Technical Support":
        case "it and technical support":
        case "IT-and-Technical-Support":
        case "ITandTechnicalSupport":
          return QuickGamerImg;
        case "personal assistance & babysitting":
        case "personal-assistance-&-babysitting":
          return QuickmotherImg;
        case "Removals":
        case "removals":
          return QuickRemovalsImg;
        case "home repairs and maintenance":
        case "home-repairs-and-maintenance":
          return QuickplumberImg;
        case "dog walking and pet care":
        case "dog-walking-and-pet-care":
          return QuickDogWalkingImg;
        case "landscaping & gardening":
        case "landscaping-&-gardening":
          return QuickGardenerImg;
        case "handyman":
        case "handy man":
        case "handy-man":
          return QuickHandymanImg;
        case "delivery driver":
          return QuickDeliveryImg;
        case "electrical repairs":
          return QuickElectricityImg;
        case "Anything-In-Between":
        case "AnythingInBetween":
        case "Anything In Between":
          return QuickAnyThing;
        default:
          return QuickAnyThing;
      }
    }
  else return isQuickTaskProject ? QuickAnyThing : customImg;
};

export const categories = (): string[] => {
  if (isQuickTaskProject) {
    return [
      "Cleaning And Ironing Services",
      "Personal Assistance & Babysitting",
      "IT and Technical Support",
      "home repairs and maintenance",
      "Landscaping & Gardening",
      "Painting & Decorating",
      "Handy Man",
      "Removals",
      "Dog Walking And Pet Care",
      "Electrical Repairs",
      "Delivery Driver",
      "Anything in between",
    ];
  } else {
    return [
      "cleaning",
      "assembly",
      "handyman",
      "delivery",
      "yardwork",
      "hauling",
      "admin",
      "computer it",
      "photography",
      "snow-removal",
      "lifting",
      "painting",
      "custom",
    ];
  }
};
