import React, {useState} from 'react';
import {Link} from "gatsby";
import {FiArrowUpRight} from "react-icons/fi";
import {environment} from "./../../environments/quickTaskEnvironemt";
import {useTheme} from "@mui/material/styles"; //
import {isQuickTaskProject} from "../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;

const InlineLink = ({
  children,
  disableArrow = false,
  variant = "primary",
  onClick,
  to,
  style = {},
  ariaControls,
  ariaHasPopup,
  disabled = false,
  spanStyle = {},
}: {
  children: React.ReactNode;
  variant?: "primary" | "secondary";
  onClick?: () => void;
  to?: string;
  style?: React.CSSProperties;
  ariaControls?: string;
  ariaHasPopup?: boolean | "false" | "true" | "menu" | "listbox" | "tree" | "grid" | "dialog";
  disabled?: boolean;
  spanStyle?: React.CSSProperties;
  disableArrow?: boolean;
}) => {
  const [hover, setHover] = useState(false);
  const theme = useTheme();

  const styles = {
    primary: {
      opacity: "1",
      cursor: "pointer",
      padding: "8px 0px",
      color: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
      position: "relative",
      transition: theme.transitions.create(["opacity"], {
        easing: "cubic-bezier(.6,.19,.04,.92)",
        duration: "0.2s",
      }),
      "&:hover": {
        opacity: "0.8",
      },
    },
    secondary: {
      cursor: "pointer",
      padding: "8px 0px",
      color: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
      position: "relative",
      opacity: "0.7",
      transition: theme.transitions.create(["opacity"], {
        easing: "cubic-bezier(.6,.19,.04,.92)",
        duration: "0.2s",
      }),
      "&:hover": {
        opacity: "1.0",
      },
    },
    disabled: {
      pointerEvents: "none",
    },
  };

  const switchHover = () => {
    setHover(!hover);
  };

  const textStyle = {color: disabled ? "grey" : "inherit", ...spanStyle};
  return to ? (
    to.includes("http") || to.includes("mailto") || to.includes("tel") ? (
      <a
        href={to}
        rel="noopener noreferrer"
        target={"_blank"}
        onClick={onClick ?? undefined}
        style={{
          ...(styles[variant] as React.CSSProperties),
          ...style,
        }}
        onMouseEnter={switchHover}
        onMouseLeave={switchHover}
      >
        <span style={textStyle}>{children}</span>
        {!disableArrow && <FiArrowUpRight style={{height: "14px", width: "14px"}} />}
      </a>
    ) : (
      <Link
        to={to}
        onClick={onClick ?? undefined}
        style={{
          ...(styles[variant] as React.CSSProperties),
          ...style,
        }}
        onMouseEnter={switchHover}
        onMouseLeave={switchHover}
      >
        <span style={textStyle}>{children}</span>
      </Link>
    )
  ) : (
    <span
      style={{
        ...(styles[variant] as React.CSSProperties),
        ...style,
      }}
      onMouseEnter={switchHover}
      onMouseLeave={switchHover}
      onClick={onClick}
      aria-controls={ariaControls}
      aria-haspopup={ariaHasPopup}
    >
      <span style={textStyle}>{children}</span>
    </span>
  );
};

export default InlineLink;