import React, {MouseEventHandler} from "react";
import Text from "../data-display/text";
import {useTheme} from "@mui/material/styles";
import {environment} from "../../environments/quickTaskEnvironemt";
import { isQuickTaskProject } from "../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;


const MenuListItem = ({
  children,
  icon,
  onClick,
  error,
  style,
  large,
  active,
  disableAutomaticClose = false,
  onClose,
}: {
  children: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: MouseEventHandler;
  error?: boolean;
  style?: React.CSSProperties;
  large?: boolean;
  active?: boolean;
  disableAutomaticClose?: boolean;
  onClose?: MouseEventHandler;
}) => {
  const theme = useTheme();
  const styles = {
    item: {
      padding: "10px 14px",
      background: "white",
      borderRadius: "7px",
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      transition: "0.3s",
      "&:hover": {
        background: "#f5f6f7",
      },
    },
    errorItem: {
      padding: "10px 14px",
      background: "white",
      borderRadius: "7px",
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      transition: "0.3s",
      "&:hover": {
        background: "rgb(255,248,248)",
      },
    },
  };
  return (
    <div
      onClick={() => {
        if (onClick) onClick(null);
        if (!disableAutomaticClose && onClose) onClose(null);
      }}
      style={{
        padding: large ? "14px 16px" : "",
        background: active ? "rgba(247, 248, 251,1)" : null,
        boxShadow: active ? "inset rgba(0, 0, 0, 0.05) 0px 0px 2px 1px" : null,
        ...style,
        ...((error ? styles.errorItem : styles.item) as React.CSSProperties),
      }}
    >
      {icon && (
        <div
          style={{
            marginRight: "18px",
            color: !active
              ? error
                ? "rgb(198,53,89)"
                : "rgb(101, 119, 134)"
              : isQuickTaskProject
              ? QuikTaskMainColor
              : theme.palette.primary.main,
            transform: "translateY(1px)",
          }}
        >
          {icon}
        </div>
      )}
      {typeof children === "string" ? (
        <Text
          variant={"body2"}
          style={{
            color: !active
              ? error
                ? "rgb(198,53,89)"
                : "rgb(20,20,20)"
              : isQuickTaskProject
              ? QuikTaskMainColor
              : theme.palette.primary.main,
          }}
        >
          {children}
        </Text>
      ) : (
        children
      )}
    </div>
  );
};

export default MenuListItem;