import React from "react";
import Text from "../../../components/data-display/text";
import {useTheme} from "@mui/system";
import SecondaryButton from "../../buttons/secondary-button";
import InlineLink from "../../../components/data-display/inline-link";

const DiscardTask = ({ onContinue, onCancel }: { onContinue: () => void, onCancel: () => void }) => {

    const theme = useTheme();

    return (
        <div style={{ background: theme.palette.background.default, height: "100%", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <Text variant={"h5"} style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center", }} black>
                Discard Task
            </Text>
            <Text variant={"body2"} style={{ color: "grey", marginLeft: "auto", marginRight: "auto", textAlign: "center", }} medium>
                {"Are you sure you want to \n discard this task?"}
            </Text>
            <div style={{ height: "24px" }}/>
            <SecondaryButton background={"#da3836"} textColor={"white"} style={{ width: "40%" }} onClick={onContinue}>
                Confirm
            </SecondaryButton>
            <InlineLink variant={"secondary"} onClick={onCancel} style={{ color: "grey"}}>
                <Text variant={"body2"} style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center", padding: "8px"  }} medium>
                    Cancel
                </Text>
            </InlineLink>
            <div style={{ height: "15%" }}/>
        </div>
    );
};

export default DiscardTask;
