import React, {useState} from "react";
import Text from "../data-display/text";
import {Link} from "gatsby";
import ButtonBase, {ButtonBaseProps} from "@mui/material/ButtonBase";
import CircularProgress from "@material-ui/core/CircularProgress";
interface BaseButtonProps extends Omit<ButtonBaseProps, "classes"> {
  isLoading?: boolean;
  icon?: any;
  to?: string;
  component?: "span" | "button";
  background?: string;
  textColor?: string;
  dark?: boolean;
  enableDisableColor?: boolean;
  hoverBackground?: string;
}

function RootElement({
  onClick,
  children,
  type = "button",
  disabled,
  enableDisableColor = false,
  isLoading,
  style,
  background,
  hoverBackground,
  textColor,
  icon,
  dark = false,
  key,
}: BaseButtonProps) {
  const [pressed, setPressed] = useState(false);
  const [hover, setHover] = useState(false);
  const styles = {
    secondary: {
      zIndex: 900,
      position: "relative",
      marginLeft: "auto",
      marginRight: "auto",
      display: "inline-flex",
      boxShadow: "0 5px 18px 0 rgba(0,0,0,0.025)",
      width: "90%",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      borderRadius: "100px",
      padding: "12px 16px",
      border: "none",
      color: "white",
      transition: "0.3s",
    },
    hover: {
      filter: "brightness(1.4)",
      boxShadow: `0 6px 24px 1px rgba(0,0,0,0.025)`,
    },
    hoverDark: {
      boxShadow: `0 6px 24px 1px rgba(0,0,0,0.045)`,
    },
    focus: {
      filter: "brightness(0.95)",
      transform: "translateY(1px)",
      boxShadow: `0 4px 12px -5px rgba(0,0,0,0.1)`,
    },
    iconSize: {
      width: "16px",
      height: "16px",
    },
    loading: {
      color: "white",
    },
    darkLoading: {
      color: "rgb(20,20,20)",
    },
  };
  return (
    <ButtonBase
      key={key}
      disableRipple
      disableTouchRipple
      type={type}
      style={{
        ...(styles.secondary as React.CSSProperties),
        ...(hover && !pressed && !dark ? styles.hover : {}),
        ...(hover && !pressed && dark ? styles.hoverDark : {}),
        ...(pressed || isLoading ? styles.focus : {}),
        background:
          disabled && enableDisableColor
            ? "rgb(200,200,200)"
            : dark
            ? hover && !pressed
              ? hoverBackground
              : background
            : background,
      }}
      disabled={disabled || isLoading}
      onFocus={() => setHover(true)}
      onBlur={() => setHover(false)}
      onMouseUp={() => setPressed(false)}
      onMouseDown={() => setPressed(true)}
      onClick={onClick ?? null}
      onMouseLeave={() => setPressed(false)}
      onTouchStart={() => setPressed(true)}
      onTouchEnd={() => setPressed(false)}
      onTouchCancel={() => setPressed(false)}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      {typeof icon === "string" ? (
        <div
          style={{
            background: `url(${icon}) center center / cover`,
            width: "20px",
            height: "20px",
            position: "absolute",
            left: "16px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />
      ) : (
        <div
          style={{
            position: "absolute",
            height: "auto",
            display: "flex",
            left: "16px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          {icon}
        </div>
      )}
      <div
        style={{
          opacity: isLoading ? "0.0" : "1.0",
          transform: `translateY(${isLoading ? "8px" : "0"})`,
          transition: "0.6s",
          transitionTimingFunction: "cubic-bezier(.15,.94,.08,1.22)",
        }}
      >
        <Text selectable={false} style={{color: textColor}} bold variant={"body2"}>
          {children}
        </Text>
      </div>

      <div
        style={{
          position: "absolute",
          width: "18px",
          height: "18px",
          opacity: isLoading ? 1 : 0,
          transform: `translateY(${isLoading ? "0px" : "-8px"})`,
          transition: "0.4s",
          transitionTimingFunction: "cubic-bezier(.15,.94,.08,1.22)",
        }}
      >
        <CircularProgress
          style={{
            color: dark ? styles.darkLoading.color : styles.loading.color,
          }}
          size={18}
          thickness={8}
        />
      </div>
    </ButtonBase>
  );
}

function SecondaryButton(props: BaseButtonProps) {
  const base = <RootElement {...props} />;

  return props.to ? (
    props.to.includes("http") ? (
      <a href={props.to} rel="noopener noreferrer" target={"_blank"}>
        {base}
      </a>
    ) : (
      <Link to={props.to} style={{display: "flex", width: "100%"}}>
        {base}
      </Link>
    )
  ) : (
    base
  );
}

export default SecondaryButton;
