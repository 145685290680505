import React, { createContext, useState, useEffect } from "react";

type ContextProps = {
    offerLoading: boolean,
    setLoadingState: (value: boolean) => void,
    offerUpdating: boolean,
    setUpdatingOffer: (value: boolean) => void,
};

export const Context = createContext<Partial<ContextProps>>({});

const OfferLoadingProvider = props => {
    const {
        loadingState: loadingState,
        children: children
    } = props;

    const [offerLoading, setLoadingState] = useState<boolean>(loadingState);
    const [offerUpdating, setUpdatingOffer] = useState<boolean>(loadingState);
    const offerLoadingContext = {
        offerUpdating,
        offerLoading,
        setLoadingState,
        setUpdatingOffer
    };

    return <Context.Provider value={offerLoadingContext}>{children}</Context.Provider>;
};

export default OfferLoadingProvider;

export const { Consumer } = Context;