import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {useTheme} from "@mui/material";
import {environment} from "../../environments/quickTaskEnvironemt";
import {isQuickTaskProject} from "../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;


function CircularProgressBar() {
  const theme = useTheme();
  const styles = {
    loading: {
  color: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
    },
  };
  return (
    <CircularProgress
      sx={{
        colorPrimary: styles.loading,
      }}
      size={18}
      thickness={8}
    />
  );
}

export default CircularProgressBar;