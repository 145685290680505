import React, { useEffect, useState } from "react";
import Text from "../../../components/data-display/text";
import {useTheme} from "@mui/system";
import { PostTaskProps } from "../post-task-wrapper";
import FormWrapper from "../../forms/form-wrapper";
import FormErrorText from "../../data-display/form-error-text";
import FormInput from "../../forms/form-input";
import {FiInfo, FiMoon, FiSun, FiSunrise, FiSunset} from "react-icons/fi";
import moment from "moment";
import {Theme} from "@mui/material/styles";
import {TextField} from "@mui/material";
import {Grid} from "@mui/material";
import {isToday} from "../../../utils/methods.utils";
import DialogBottomButton from "../../../components/dialogs/dialog-bottom-button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {Button} from "gatsby-theme-material-ui";
import classNames from "classnames";
import {environment} from "../../../environments/quickTaskEnvironemt";
import {isQuickTaskProject, shortDateFormatwith_DD} from "../../../utils/constant.urls";
  
const QuikTaskMainColor = environment.mainColor;
const TheMainColor = isQuickTaskProject ? QuikTaskMainColor : "rgb(78, 62, 253)";

const TaskDateForm = ({task, updateTask, onContinue}: PostTaskProps) => {
  const theme = useTheme<any>();
  const styles = {
    timingIcon: {
      userSelect: "none",
      WebkitTapHighlightColor: "transparent",
      cursor: "pointer",
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "white",
      border: "2px solid rgb(240,240,240)",
      borderRadius: "6px",
      width: "100%",
      padding: "16px 0",
      boxSizing: "border-box",
      transition: "0.3s",
      "&:hover": {
        boxShadow: "0 4px 6px 0 rgba(0,0,0,0.02)",
      },
    },
    timingSelectedIcon: {
      userSelect: "none",
      WebkitTapHighlightColor: "transparent",
      cursor: "pointer",
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "white",
      border: `2px solid ${isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main}`,
      borderRadius: "6px",
      width: "100%",
      padding: "16px 0",
      boxSizing: "border-box",
      transition: "0.3s",
      "&:hover": {
        boxShadow: "0 4px 6px 0 rgba(0,0,0,0.02)",
      },
    },
    padding: {
      padding: "12px 16px",
    },
    input: {
      width: "100%",
      color: "black",
      background: "white",
      border: "2px solid rgb(232,232,232,0.9)",
      borderRadius: "8px",
      lineHeight: "1.5",
      boxSizing: "border-box",
      fontFamily: "'HKGroteskMedium', sans-serif !important",
      flexGrow: 1,
      margin: "8px 0px",
      boxShadow: "0px 0px 12px 0px rgba(70,68,137,0.0)",
      fontSize: "16px",
      height: "48px !important",
      transition: theme.transitions.create(["box-shadow", "border", "background"], {
        easing: theme.transitions.easing.easeInOut,
        duration: "0.3s",
      }),
    },
  };
  const palette_Color = isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main;
  const [formError, setFormError] = useState<string | null>(null);
  const [date, setDate] = useState<Date>(null);
  const [timings, setTimings] = useState<string[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [isStartOpen, setIsStartOpen] = useState<boolean>(false);
  const [isbeforeOpen, setIsBeforeOpen] = useState<boolean>(false);
  const [startOnDate, setStartOnDate] = useState("On");
  const [beforeDate, setBeforeDate] = useState("Date");
  const [timing, setTiming] = useState<string[]>();

  //   const handleChange = (e) => {
  //   };
  const handleClick = (type: string): void => {
    let active = -1;
    switch (type) {
      case "on":
        active = 0;
        setIsBeforeOpen(false);
        setIsStartOpen((prev: boolean) => !prev);
        break;
      case "before":
        active = 1;
        setIsStartOpen(false);
        setIsBeforeOpen((prev: boolean) => !prev);
        break;
      case "flex":
        active = 2;
        setIsBeforeOpen(false);
        setIsStartOpen(false);
        task.dateToStart = new Date();
        const numberOfDays = 30;
        const result = task.dateToStart.setDate(task.dateToStart.getDate() + numberOfDays);
        task.dateToStart = new Date(result);
        task.dateType = "flex";
        updateTask(task);
        break;
      default:
        active = -1;
        setIsBeforeOpen(false);
        setIsStartOpen(false);
    }
    setActiveIndex(active);
  };

  useEffect(() => {
    setDate(task.dateToStart);
    setTimings(task.timings ?? []);
  }, [task.dateToStart, task.timings]);
  const toggleTiming = (value: string) => {
    let t = [...timings];
    if (t.includes(value)) t.splice(t.indexOf(value), 1);
    else t.push(value);
    setTimings(t);
    task.timings = t;
    updateTask(task);
  };
  const removeTimings = (values: string[]) => {
    if ((values.length = 0)) {
      let t = [...timings];
      values.forEach((value) => {
        if (t.includes(value)) t.splice(t.indexOf(value), 1);
      });
      setTimings(t);
      task.timings = t;
      updateTask(task);
    }
  };

  const goNext = () => {
    return new Promise((resolve, reject) => {
      let error;
      if (task.dateToStart >= new Date() || activeIndex === 2) {
        if (!error) {
          onContinue();
        } else {
          setFormError(error);
          reject(error);
        }
      } else {
        const error = "Task date shouldn't be in the past.";
        setFormError(error);
        reject(error);
      }
    });
  };

  const checkDate = (hours: number, customDate?: Date) => {
    if (customDate)
      return (customDate &&
        ((isToday(customDate) && new Date().getHours() < hours) ||
          !isToday(customDate))) as boolean;
    else
      return (date &&
        ((isToday(date) && new Date().getHours() < hours) || !isToday(date))) as boolean;
  };
  const onValueChange = (values: Record<string, any>) => {
    const newDate = new Date(values?.dateToStart + "T22:00:00");
    const beforeDate = new Date(values?.before + "T22:00:00");

    //date to start
    if (activeIndex === 0 && newDate >= new Date()) {
      task.dateToStart = newDate;
      task.dateType = "on";
      setBeforeDate("Date");
      setIsStartOpen(false);
      setStartOnDate(moment(task.dateToStart).format(shortDateFormatwith_DD));

      updateTask(task);
      let dateCheck = new Date(values.dateToStart + "T22:00:00");
      if (isToday(task.dateToStart)) {
        dateCheck = new Date();
      }
      setDate(new Date(dateCheck));
      let removal = [];
      if (!checkDate(10, dateCheck)) removal.push("morning");
      if (!checkDate(14, dateCheck)) removal.push("midday");
      if (!checkDate(18, dateCheck)) removal.push("afternoon");
      if (!checkDate(23, dateCheck)) removal.push("evening");
      removeTimings(removal);
    } else if (activeIndex === 1 && beforeDate >= new Date()) {
      task.dateToStart = beforeDate;
      task.dateType = "before";
      updateTask(task);

      setIsBeforeOpen(false);
      setStartOnDate("On");
      setBeforeDate(moment(task.dateToStart).format(shortDateFormatwith_DD));

      let dateCheck = new Date(values.dateToStart + "T22:00:00");
      if (isToday(task.dateToStart)) {
        dateCheck = new Date();
      }
      setDate(new Date(dateCheck));
      let removal = [];
      if (!checkDate(10, dateCheck)) removal.push("morning");
      if (!checkDate(14, dateCheck)) removal.push("midday");
      if (!checkDate(18, dateCheck)) removal.push("afternoon");
      if (!checkDate(23, dateCheck)) removal.push("evening");
      removeTimings(removal);
    } else if (activeIndex === 2) {
      values.before = "";
      values.dateToStart = "";

      let dateCheck = new Date(values.dateToStart + "T22:00:00");
      if (isToday(task.dateToStart)) {
        dateCheck = new Date();
      }
      setDate(new Date(dateCheck));
      let removal = [];
      if (!checkDate(10, dateCheck)) removal.push("morning");
      if (!checkDate(14, dateCheck)) removal.push("midday");
      if (!checkDate(18, dateCheck)) removal.push("afternoon");
      if (!checkDate(23, dateCheck)) removal.push("evening");
      removeTimings(removal);
    } else {
      task.dateToStart = newDate;
      task.timings = [];
      updateTask(task);
      setDate(new Date(newDate));
      setTimings([]);
    }
  };

  return (
    <div>
      <FormWrapper
        onSubmit={goNext}
        style={{marginTop: "16px", position: "unset"}}
        onValueChange={onValueChange}
      >
        <Text variant={"body2"} style={{color: palette_Color}} medium>
          When do you need it done?{" "}
        </Text>
        <Grid container>
          <Grid item lg={6}>
            <Button
              placeholder="on"
              onClick={() => handleClick("on")}
              style={{
                backgroundColor: activeIndex === 0 ? TheMainColor : "transparent",
                borderRadius: "50px",
                color: activeIndex === 0 ? "white" : "black",
                display: "flex",
                border: "2px solid #DDDDDD",
                width: "90%",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Start {startOnDate}
              <ArrowDropDownIcon style={{height: "18px"}} />
            </Button>
            {isStartOpen && (
              <>
                <TextField
                  // min={moment().format("DD-MM-YYYY")}
                  placeholder={"Select a date"}
                  name={"dateToStart"}
                  type={"date"}
                  onChange={(e) => {
                    onValueChange({dateToStart: e.target.value, before: ""});
                  }}
                  required
                  style={{
                    ...(styles.input as React.CSSProperties),
                  }}
                />
              </>
            )}
          </Grid>
          <Grid item lg={6}>
            <Button
              placeholder="before"
              className="example-custom-input"
              onClick={() => handleClick("before")}
              style={{
                backgroundColor: activeIndex === 1 ? TheMainColor : "transparent",
                borderRadius: "50px",
                color: activeIndex === 1 ? "white" : "black",
                border: "2px solid #DDDDDD",
                display: "flex",
                width: "90%",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Before {beforeDate}
              <ArrowDropDownIcon style={{height: "18px"}} />
            </Button>
            {isbeforeOpen && (
              <>
                <TextField
                  // min={moment().format("DD-MM-YYYY")}
                  id="outlined-basic"
                  placeholder={"Select a date"}
                  name={"before"}
                  type={"date"}
                  required
                  onChange={(e) => {
                    onValueChange({dateToStart: "", before: e.target.value});
                  }}
                  style={{
                    ...(styles.input as React.CSSProperties),
                  }}
                />
              </>
            )}
          </Grid>
          <Grid item lg={6}>
            <Button
              placeholder="flex"
              onClick={() => handleClick("flex")}
              style={{
                backgroundColor: activeIndex === 2 ? TheMainColor : "transparent",
                borderRadius: "50px",
                color: activeIndex === 2 ? "white" : "black",
                border: "2px solid #DDDDDD",
                display: "flex",
                marginTop: "6px",
                width: "90%",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              I'm flexible
            </Button>
          </Grid>
        </Grid>
        <Text
          variant={"body2"}
          style={{
            color: palette_Color,
            marginTop: "16px",
            marginBottom: "12px",
          }}
          medium
        >
          What time(s) do you need the Tasker?
        </Text>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div
              style={{
                ...(styles.timingIcon as React.CSSProperties),
                ...(timings.includes("morning") && date >= new Date()
                  ? (styles.timingSelectedIcon as React.CSSProperties)
                  : {}),
              }}
              onClick={() => {
                if (checkDate(10) && date >= new Date()) toggleTiming("morning");
              }}
            >
              <FiSunrise
                size={28}
                style={{
                  color: checkDate(10) && date >= new Date() ? palette_Color : "grey",
                }}
              />
              <Text variant={"body2"} style={{marginTop: "8px"}}>
                Morning
              </Text>
              <Text variant={"caption"} style={{opacity: "0.6"}}>
                Before 10AM
              </Text>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                ...(styles.timingIcon as React.CSSProperties),
                ...(timings.includes("midday") && date >= new Date()
                  ? (styles.timingSelectedIcon as React.CSSProperties)
                  : {}),
              }}
              onClick={() => {
                if (checkDate(14) && date >= new Date()) toggleTiming("midday");
              }}
            >
              <FiSun
                size={28}
                style={{
                  color: checkDate(14) && date >= new Date() ? palette_Color : "grey",
                }}
              />
              <Text variant={"body2"} style={{marginTop: "8px"}}>
                Midday
              </Text>
              <Text variant={"caption"} style={{opacity: "0.6"}}>
                {isQuickTaskProject ? "10:00 - 14:00" : "10AM - 2PM"}
              </Text>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                ...(styles.timingIcon as React.CSSProperties),
                ...(timings.includes("afternoon") && date >= new Date()
                  ? (styles.timingSelectedIcon as React.CSSProperties)
                  : {}),
              }}
              onClick={() => {
                if (checkDate(18) && date >= new Date()) toggleTiming("afternoon");
              }}
            >
              <FiSunset
                size={28}
                style={{
                  color: checkDate(18) && date >= new Date() ? palette_Color : "grey",
                }}
              />
              <Text variant={"body2"} style={{marginTop: "8px"}}>
                Afternoon
              </Text>
              <Text variant={"caption"} style={{opacity: "0.6"}}>
                {isQuickTaskProject ? "14:00 - 18:00" : " 2PM - 6PM"}
              </Text>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                ...(styles.timingIcon as React.CSSProperties),
                ...(timings.includes("evening") && date >= new Date()
                  ? (styles.timingSelectedIcon as React.CSSProperties)
                  : {}),
              }}
              onClick={() => {
                if (checkDate(23) && date >= new Date()) toggleTiming("evening");
              }}
            >
              <FiMoon
                size={28}
                style={{
                  color: checkDate(23) && date >= new Date() ? palette_Color : "grey",
                }}
              />
              <Text variant={"body2"} style={{marginTop: "8px"}}>
                Evening
              </Text>
              <Text variant={"caption"} style={{opacity: "0.6"}}>
                {isQuickTaskProject ? "After 18:00" : "After 6PM"}
              </Text>
            </div>
          </Grid>
        </Grid>
        <div style={{display: "flex", marginTop: "14px"}}>
          <FiInfo style={{color: "grey"}} size={14} />
          <Text variant={"caption"} style={{color: "grey", marginLeft: "12px"}} medium>
            You can sort out exact times with Tasker later.
          </Text>
        </div>
        <FormErrorText style={{marginTop: "16px"}} />
        {formError && <div style={{marginTop: "5px"}} />}
        <DialogBottomButton type={"submit"}>Continue</DialogBottomButton>
      </FormWrapper>
    </div>
  );
};
export default TaskDateForm;
