import React, { useEffect, useState } from "react";
import Text from "../../../components/data-display/text";
import {useTheme} from "@mui/system";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { PostTaskProps } from "../post-task-wrapper";
import FormWrapper from "../../forms/form-wrapper";
import FormInput from "../../forms/form-input";
import FormSwitch from "../../input-feilds/form-switch";
import AddressHandler from "../../../components/address-handler/address-handler";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/index.reducers";
import FormErrorText from "../../data-display/form-error-text";
import DialogBottomButton from "../../../components/dialogs/dialog-bottom-button";
import {Theme} from "@mui/material/styles";
import {environment} from "../../../environments/quickTaskEnvironemt";
import { isQuickTaskProject } from "../../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;
const Fade = require("react-reveal/Fade");

const TaskDetailsForm = ({task, updateTask, onContinue}: PostTaskProps) => {
  const theme = useTheme();

  const styles = {
    root: {
      width: 60,
      height: 30,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: "7px",
      color: theme.palette.common.white,
      transition: "0.4s",
      transitionTimingFunction: "cubic-bezier(0.23, 1.0, 0.32, 1.0)",
      "&$checked": {
        transform: "translateX(30px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 16,
      height: 16,
      boxShadow: "0 4px 5px rgba(66,66,66,0.15)",
    },
    track: {
      borderRadius: 20,
      opacity: 1,
      backgroundColor: "rgba(224,224,224)",
    },
    checked: {},
  };

  const palette_Color = isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main;
  const [text, setText] = useState<string>("minimum 10 characters");
  const [color, setColor] = useState<string>("gray");
  const [description, setDescription] = useState<string>("minimum 25 characters");
  const [isRemote, setIsRemote] = useState<boolean>(task.isRemote);
  const {defaultTaskAddressID, addresses} = useSelector((state: RootState) => state.userData);

  useEffect(() => {
    // setIsRemote(task.isRemote);
    const defualtAddress = addresses.find((item) => item?.id === defaultTaskAddressID);
    if (defualtAddress) {
      task.address = defualtAddress;
      updateTask(task);
    }
  }, [task, defaultTaskAddressID]);

  const onValueChange = (values: Record<string, any>) => {
    if (values.description?.length < 25) {
      setDescription("minimum 25 characters");
    }
    if (values.description?.length > 25) {
      setDescription("");
    }
    if (values.title?.length > 10 && values.title?.length <= 80) {
      setColor("rgb(50, 199, 161)");
      setText(`${80 - values.title?.length < 0 ? 0 : 80 - values.title?.length} characters left`);
    }
    if (values.title?.length < 10) {
      setColor("gray");
      setText("minimum 10 characters");
    }
    task.title = values.title ?? "";
    task.description = values.description ?? "";
    task.isRemote = values.isRemote ?? false;
    updateTask(task);
  };

  const goNext = (values: Record<string, any>) => {
    console.warn(values);

    return new Promise((resolve, reject) => {
      let error;
      if (values.title?.length < 10) {
        if (!error) error = {};
        error["title"] = "Please enter at least 10 characters.";
      }
      if (
        values.title?.replace(/ /g, "").length === 0 ||
        values?.description?.replace(/ /g, "").length === 0
      ) {
        if (!error) error = {};
        error["title"] = "Please enter at least 10 characters.";
        error["description"] = "Please enter at least 25 characters.";
      }
      if (values.title.replace(/ /g, "").length < 10) {
        if (!error) error = {};
        error["title"] = "Please enter at least 10 characters.";
      }
      if (values.title?.length > 80) {
        if (!error) error = {};
        error["title"] = "Please enter a maximum of 80 characters.";
      }
      if (values.description?.replace(/ /g, "").length < 25) {
        if (!error) error = {};
        error["description"] = "Please enter at least 25 characters.";
      }
      if (values.description?.length < 25) {
        if (!error) error = {};
        error["description"] = "Please enter at least 25 characters.";
      }
      if (!isRemote && !defaultTaskAddressID) {
        if (!error) error = {};
        error["text"] = "Please add a valid Task Address.";
      }
      if (
        !isQuickTaskProject &&
        task?.address?.state?.toUpperCase() !== "WI" &&
        task?.address?.state?.toUpperCase() !== "MN" &&
        task?.address?.state?.toUpperCase() !== "IL" &&
        task?.address?.state?.toUpperCase() !== "TX" &&
        !isRemote
      ) {
        if (!error) error = {};
        error["text"] =
          "We do not provide services in your area yet, please choose another address.";
      }
      if (!error) {
        task.title = values.title ?? "";
        task.description = values.description ?? "";
        task.isRemote = isRemote ?? false;
        updateTask(task);
        onContinue();
      } else {
        reject(error);
      }
    });
  };

  return (
    <FormWrapper
      onSubmit={goNext}
      initialValues={{
        title: task.title ?? "",
        description: task.description ?? "",
        isRemote: task.isRemote ?? false,
      }}
      style={{marginTop: "16px", position: "unset"}}
      onValueChange={onValueChange}
    >
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <Text variant={"body2"} style={{color: palette_Color}} medium>
          Task Title
        </Text>
        <Text variant={"caption"} style={{color: color}}>
          {text}
        </Text>
      </div>
      <FormInput
        name={"title"}
        defaultValue={task?.title}
        autoCapitalize={"sentences"}
        placeholder={"What do you need done?"}
        required
      />

      {/* <FormInput onChange={(el) => {
            let val = el?.target?.value;
            console.log(val)
          }} name={"title"} maxLength="80" autoCapitalize={"sentences"} placeholder={"What do you need done?"} required /> */}
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <Text variant={"body2"} style={{color: palette_Color, marginTop: "16px"}} medium>
          Describe your Task
        </Text>
        <Text variant={"caption"} style={{marginTop: "18px", color: "gray"}}>
          {description}
        </Text>
      </div>
      <FormInput
        name={"description"}
        placeholder={"Add a more detailed description about the task you want done"}
        rowsMin={3}
        rowsMax={4}
        rows={3}
        multiline
        required
      />
      <div
        style={{
          marginTop: "24px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text variant={"body2"} medium>
          Can this task be completed remotely?
        </Text>
        {/* <FormSwitch name={"isRemote"} /> */}
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={isRemote}
                onChange={() => {
                  setIsRemote(!isRemote);
                }}
                sx={{
                  "& .MuiSwitch-thumb": {
                    width: 20,
                    height: 20,
                    backgroundColor: "#fff",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                  },
                  "& .Mui-checked .MuiSwitch-thumb": {
                    backgroundColor: isQuickTaskProject
                      ? QuikTaskMainColor
                      : theme.palette.common.white,
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: "rgba(0, 0, 0, 0.25)",
                    borderRadius: 16,
                    height: 12,
                  },
                  "& .MuiSwitch-root": {
                    width: 60,
                    height: 36,
                  },
                }}
              />
            }
            label=""
          />
        </div>
      </div>
      <Fade duration={300} when={!isRemote && isRemote !== null} mountOnEnter unmountOnExit>
        <div style={{marginTop: "12px"}} />
        <Text variant={"body2"} style={{color: palette_Color, margin: "8px 0"}} medium>
          Task Address
        </Text>
        <AddressHandler height={"550px"} type={"task"} />
      </Fade>
      <FormErrorText style={{marginTop: "14px"}} />
      <DialogBottomButton type={"submit"}>Continue</DialogBottomButton>
    </FormWrapper>
  );
};

export default TaskDetailsForm;
