import React,{useState} from "react";
import {Notification} from "../../models/notification";
import Text from "../data-display/text";
import Avatar from "../avatar/avatar";
import {useTheme} from "@mui/system";
import {FaHandPointUp} from "react-icons/fa";
import {
  getNotificationBody,
  getNotificationColor,
  getNotificationIcon,
  notificationHasIcon,
} from "./icon-util";
import moment from "moment";
import {Link} from "gatsby";
import {notifications} from "../../utils/firebase.utils";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import StarRating from "../reviews-ratings/star-rating";
import useUsersData from "../../../src/hooks/useUsersData";
import {UserData} from "../../models/user-data";
import { environment } from "../../environments/quickTaskEnvironemt";
import { isQuickTaskProject } from "../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;
const styles = {
  item: {
    padding: "10px 8px",
    background: "white",
    borderRadius: "7px",
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "start",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "#f5f6f7",
    },
  },
  itemNew: {
    padding: "10px 8px",
    borderRadius: "7px",
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "start",
    cursor: "pointer",
    transition: "0.3s",
    background: "#f9f9ff",
    "&:hover": {
      background: "#f5f6f7",
    },
  },
};
const NotificationCard = ({notification}: {notification: Notification}) => {
    const {userData} = useSelector((state: RootState) => state.userData);
    const theme = useTheme();
    const palette_Color = isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main;
   const review = notification?.options?.rating;
   const [link,setLink]=React.useState("");

  const to = () => {
    if (notification.type === "new_offer" || notification.type === "offer_updated") {
      setLink("/my-tasks/poster/" + notification.taskId);
    } else if (notification.type === "new_review") {
      setLink("/profile" + userData?.slug_title);
    } else if (notification.type === "payout_paid" || notification.type === "payout_created") {
      setLink("/account/payment-history");
    } else if (
      notification.type === "payment_released" ||
      notification.type === "assign" ||
      notification.type === "task_update" ||
      notification.type === "increase-price-by-poster"
    ) {
      setLink("/my-tasks/tasker/" + notification.taskId);
    } else if (
      notification.type === "verified_user_profile" ||
      notification.type === "rejected_user_profile"
    ) {
      setLink("/profile" + userData?.slug_title);
    } else if (notification.type === "missed_call") {
      setLink(notification?.dynamicLink);
    } else if (notification?.type === "unread_message") {
      setLink(`/messaging/${notification?.taskId}_${notification?.uid}`);
    } else {
      setLink("/my-tasks/poster/" + notification.taskId);
    }
  };
  React.useEffect(() => {
    to();
  }, []);

  return (
    <Link to={link}>
      <div
        style={{
          ...((notification.opened ? styles.item : styles.itemNew) as React.CSSProperties),
        }}
        onClick={() => {
          notifications(userData?.uid)
            .doc(notification.notificationId)
            .update({opened: true})
            .then();
        }}
      >
        <div style={{position: "relative"}}>
          <Avatar
            name={notification.firstName}
            profilePicture={notification.profilePicture}
            size={52}
            style={{position: "relative", zIndex: 1}}
          />
          {notificationHasIcon(notification.type) && (
            <div style={{position: "absolute", right: "-6px", bottom: "-2px", zIndex: 1}}>
              <div
                style={{
                  width: "28px",
                  height: "28px",
                  background: getNotificationColor(notification.type),
                  boxShadow: "3px 2px 12px -2px rgba(0,0,0,0.2)",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {getNotificationIcon(notification.type)}
              </div>
            </div>
          )}
        </div>
        <div style={{width: "24px"}} />
        <div style={{opacity: notification.opened ? 0.8 : 1}}>
          {notification.type === "new_review" && <StarRating totalAverage={parseInt(review)} />}
          {getNotificationBody(notification)}
          <div style={{height: "4px"}} />
          <Text
            variant={"caption"}
            medium
            style={{color: notification.opened ? "grey" : palette_Color}}
          >
            {moment(notification.createAt).fromNow(false)}
          </Text>
        </div>
        <div style={{marginLeft: "4px", marginRight: "8px"}}>
          <div
            style={{
              width: "12px",
              height: "12px",
              opacity: notification.opened ? 0 : 1,
              background: palette_Color,
              borderRadius: "50%",
            }}
          />
        </div>
      </div>
    </Link>
  );
};

export default NotificationCard;