import React from "react";
import {SwitchProps} from "@mui/material/Switch";
import Switch from "@mui/material/Switch";
import {useTheme} from "@mui/material";
import { environment } from "../../environments/quickTaskEnvironemt";
import { isQuickTaskProject } from "../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;

const FormSwitch = (props: SwitchProps) => {
  const theme = useTheme();
  const styles = {
    root: {
      width: 60,
      height: 30,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: "7px",
      color: theme.palette.common.white,
      transition: "0.4s",
      transitionTimingFunction: "cubic-bezier(0.23, 1.0, 0.32, 1.0)",
      "&$checked": {
        transform: "translateX(30px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 16,
      height: 16,
      boxShadow: "0 4px 5px rgba(66,66,66,0.15)",
    },
    track: {
      borderRadius: 20,
      opacity: 1,
      backgroundColor: "rgba(224,224,224)",
    },
    checked: {},
  };
  return (
    <Switch
      {...props}
      disableFocusRipple
      disableRipple
      disableTouchRipple
      sx={{
        "& .MuiSwitch-thumb": {
          width: 20,
          height: 20,
          backgroundColor: "#fff",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        },
        "& .Mui-checked .MuiSwitch-thumb": {
          backgroundColor: isQuickTaskProject ? QuikTaskMainColor : theme.palette.common.white,
        },
        "& .MuiSwitch-track": {
          backgroundColor: "rgba(0, 0, 0, 0.25)",
          borderRadius: 16,
          height: 12,
        },
        "& .MuiSwitch-root": {
          width: 60,
          height: 36,
        },
      }}
    />
  );
};

export default FormSwitch;
