import React from 'react';
import Typography from "@mui/material/Typography";
import ContnetModerator from "./content-moderator";
import {useTheme} from "@mui/system";

interface TextProps extends React.ComponentPropsWithoutRef<"span"> {
  variant?: string;
  component?: any;
  children: React.ReactNode;
  style?: React.CSSProperties;
  regular?: boolean;
  medium?: boolean;
  bold?: boolean;
  black?: boolean;
  sydeLogo?: boolean;
  selectable?: boolean;
  noWrap?: boolean;
  id?: string | undefined;
}

function Text(props: TextProps) {
  const theme = useTheme<any>();

  const styles = {
    base: {
      zIndex: 1000,
      color: "rgb(10,10,10)",
      whiteSpace: "break-spaces",
      wordBreak: "break-word",
    },
    h1: {
      fontFamily: "'HKGroteskBlack', sans-serif !important",
      lineHeight: "normal !important",
      fontSize: "45px !important",
      [theme.breakpoints.down("lg")]: {
        fontSize: "40px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "36px !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px !important",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "24px !important",
      },
    },
    h2: {
      fontFamily: "'HKGroteskBlack', sans-serif !important",
      fontSize: "40px !important",
      [theme.breakpoints.down("lg")]: {
        fontSize: "30px !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "26px !important",
      },
    },
    h3: {
      fontFamily: "'HKGroteskMedium', sans-serif !important",
      fontSize: "36px !important",
      [theme.breakpoints.down("lg")]: {
        fontSize: "22px !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "28px !important",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "22px !important",
      },
    },
    h4: {
      fontFamily: "'HKGroteskMedium', sans-serif !important",
      fontSize: "32px !important",
      [theme.breakpoints.down("lg")]: {
        fontSize: "26px !important",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "22px !important",
      },
    },
    h5: {
      fontFamily: "'HKGroteskBlack', sans-serif !important",
      fontSize: "26px !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px !important",
      },
    },
    h6: {
      fontFamily: "'HKGroteskMedium', sans-serif !important",
      fontSize: "22px !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px !important",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px !important",
      },
    },
    body1: {
      fontFamily: "'HKGroteskMedium', sans-serif !important",
      fontSize: "20px !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px !important",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px !important",
      },
    },
    body2: {
      letterSpacing: "-0.2px !important",
      fontFamily: "'HKGroteskBold', sans-serif !important",
      fontSize: "16px !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px !important",
      },
    },
    subtitle2: {
      fontFamily: "'HKGroteskBold', sans-serif !important",
    },
    subtitle1: {
      fontFamily: "'HKGroteskMedium', sans-serif !important",
      fontSize: "11px !important",
      letterSpacing: "0 !important",
      lineHeight: "1.2 !important",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px !important",
      },
    },
    overline: {
      fontFamily: "'HKGroteskExtraBold', sans-serif !important",
      fontSize: "10px !important",
      letterSpacing: "1.4px !important",
      [theme.breakpoints.down("lg")]: {
        fontSize: "9px !important",
      },
    },
    caption: {
      fontFamily: "'HKGroteskMedium', sans-serif !important",
      fontSize: "13px !important",
      letterSpacing: "0 !important",
      lineHeight: "1.2 !important",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px !important",
      },
    },
    xl: {
      fontSize: "40px !important",
      textTransform: "uppercase",
    },
    navigation: {
      alignItems: "center",
      height: "100%",
      display: "flex",
    },
    regular: {
      fontFamily: "'HKGroteskMedium', sans-serif !important",
    },
    medium: {
      fontFamily: "'HKGroteskBold', sans-serif !important",
    },
    bold: {
      fontFamily: "'HKGroteskExtraBold', sans-serif !important",
    },
    black: {
      fontFamily: "'HKGroteskBlack', sans-serif !important",
    },
    sydeLogo: {
      fontFamily: "'Blogger Sans', !important",
    },
  };
  const {
    variant,
    noWrap,
    children,
    style,
    medium,
    bold,
    black,
    sydeLogo,
    selectable = true,
    onClick,
    onMouseEnter,
    onMouseLeave,
    component,
    id,
    regular,
    className,
  } = props;
  return (
    <Typography
      component={component}
      id={id}
      onClick={onClick}
      noWrap={noWrap}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      sx={{
        ...styles.base,
        ...styles[variant],
        ...(bold && styles.bold),
        ...(medium && styles.medium),
        ...(black && styles.black),
        ...(sydeLogo && styles.sydeLogo),
        ...(regular && styles.regular),
        ...{className},
        h1: styles.h1,
        h2: styles.h2,
        h3: styles.h3,
        h4: styles.h4,
        h5: styles.h5,
        body1: styles.body1,
        body2: styles.body2,
        caption: styles.caption,
        overline: styles.overline,
      }}
      style={{
        userSelect: selectable ? "unset" : "none",
        ...style,
      }}
    >
      {!children?.toString().includes("[Content Moderated]") ? (
        children
      ) : (
        <span>
          {children?.toString().split("[Content Moderated]")[0]}
          <ContnetModerator />
          {children?.toString().split("[Content Moderated]")[1]}
        </span>
      )}
    </Typography>
  );
}

export default Text;