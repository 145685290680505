import React, {useState} from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import {useTheme} from "@mui/material";
import {ArrowUpRight} from "react-feather";
import {environment} from "../../environments/quickTaskEnvironemt";
import { isQuickTaskProject } from '../../utils/constant.urls';
const QuikTaskMainColor = environment.mainColor;
function InlineLink({
  children,
  variant = "primary",
  onClick,
  to,
  style = {},
  ariaControls,
  ariaHasPopup,
  disabled,
  spanStyle = {},
}) {
  const [hover, setHover] = useState(false);
  const theme = useTheme();

  const styles = {
    primary: {
      opacity: "1",
      cursor: "pointer",
      padding: "8px 0px",
      color: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
      position: "relative",
      transition: theme.transitions.create(["opacity"], {
        easing: "cubic-bezier(.6,.19,.04,.92)",
        duration: "0.2s",
      }),
    },
    secondary: {
      opacity: "1",
      cursor: "pointer",
      padding: "8px 0px",
      color: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
      position: "relative",
      transition: theme.transitions.create(["text-decoration"], {
        easing: "cubic-bezier(.6,.19,.04,.92)",
        duration: "0.2s",
      }),
      "&:hover": {
        textDecoration: "underline",
      },
    },
    disabled: {
      pointerEvents: "none",
    },
  };

  const switchHover = () => {
    setHover(!hover);
  };

  const textStyle = {color: disabled ? "grey" : "inherit", ...spanStyle};

  return to ? (
    to.includes("http") ? (
      <a
        href={to}
        rel="noopener noreferrer"
        target={"_blank"}
        className={classNames({[variant]: true, disabled})}
        style={{
          ...(styles[variant] as React.CSSProperties),
          ...style,
        }}
        onMouseEnter={switchHover}
        onMouseLeave={switchHover}
      >
        <span style={textStyle}>{children}</span>
        <ArrowUpRight style={{height: "14px", width: "14px"}} />
      </a>
    ) : (
      <Link
        to={to}
        className={classNames({[variant]: true, disabled})}
        style={{
          ...(styles[variant] as React.CSSProperties),
          ...style,
        }}
        onMouseEnter={switchHover}
        onMouseLeave={switchHover}
      >
        <span style={textStyle}>{children}</span>
      </Link>
    )
  ) : (
    <span
      className={classNames({[variant]: true, disabled})}
      style={{
        ...(styles[variant] as React.CSSProperties),
        ...style,
      }}
      onMouseEnter={switchHover}
      onMouseLeave={switchHover}
      onClick={onClick}
      aria-controls={ariaControls}
      aria-haspopup={ariaHasPopup}
    >
      <span style={textStyle}>{children}</span>
    </span>
  );
}

InlineLink.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    variant: PropTypes.oneOf(["primary", "secondary"]),
    onClick: PropTypes.func,
    to: PropTypes.string,
    style: PropTypes.object,
    ariaControls: PropTypes.string,
    ariaHasPopup: PropTypes.string
};

export default InlineLink;