import React from "react"
import Layout from "./src/components/layout/layout";
import firebase from 'gatsby-plugin-firebase';
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import './src/components/layout/layout.css'
import {createStore} from "redux";
import allReducers from "./src/redux/index.reducers";
import {Provider} from "react-redux";

export const wrapRootElement = ({ element }) => {

    const store = createStore(allReducers);

    return (
        <Provider store={store}>
            {element}
        </Provider>
    )
}
export const wrapPageElement = ({ element, props }) => {

    firebase.analytics();

    return (
        <Layout {...props} >
            {element}
        </Layout>
    )
}

// export const wrapRootElement = wrapWithProvider;
