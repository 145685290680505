
export const GooglePlaces = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAqtUypQObeGz4IGYx56-D3A3HhifQeRmk&libraries=places&language=en';
export const GoogleMaps = 'AIzaSyBjFEh8BnuyHRNju8Z7gmnO6PuZJ0x-41w';

let ALGOLIA_APP_ID: string;
let ALGOLIA_API_KEY: string;
let STRIPE_KEY: string;
let baseUrl: string;
let endPoint: string;
let storageFB: string;
let project: string;
let currency: string;
let country: string;
let currencySymbol: string;
let shortDateFormatwith_dd: string;
let shortDateFormatwith_DD: string;
let longDateFormatWith_DD: string;
let longDateFormatWith_DDWithTime: string;
let longDateFormatwithSeparator: string;
let WebsiteLink: string;
let shortWebsiteLink: string;
let GooglePlayLink: string;
let appStoreLink: string;
let title: string;
let countryCodeCapitalForStripe: string;
let descriptionInfo: string;
let currencyUnit: string;
let yardWork: string;
let logoJPG: string;
let CVname: string;
let env_Value: string;
let defaultLocationInfo: object;
const isQuickTaskProject = process.env.GATSBY_APP_ENV.includes("quicktask");
console.log("ENV", process.env.GATSBY_APP_ENV);
if (
  process.env.GATSBY_APP_ENV === "production" ||
  process.env.GATSBY_APP_ENV === "quicktask-prod"
) {
  env_Value = "prod";
  
  if (process.env.GATSBY_APP_ENV.includes("quicktask-prod")) {
    ALGOLIA_APP_ID = "PTFUMGZLO7";
    ALGOLIA_API_KEY = "26bcedcd76a7417bf0e89871f5229554";
  } else {
    ALGOLIA_APP_ID = "RU30XYALZ8";
    ALGOLIA_API_KEY = "1205d7f2f76daa3020ab7e2a05b6d6f4";
  }
  if (process.env.GATSBY_APP_ENV === "quicktask-prod") {
    STRIPE_KEY =
      "pk_live_51Oc3jGChRLDqKMwoDlT1ENowxeMgATX1uRMIngg9MxOmJP9UnTfrpKUHcE7aLSLC6wc0ECzCcVN0DuRoPqryGuyW00ejtTUSkG";
  } else {
    STRIPE_KEY = "pk_live_8ZBaouvp0k3nv0NjKlRvJWNp001Qbjdiuu";
  }
  if (isQuickTaskProject) {
    baseUrl = "https://us-central1-quicktask-prod-e2bc9.cloudfunctions.net";
  } else {
    baseUrl = "https://us-central1-sydework-api.cloudfunctions.net";
  }
  endPoint = isQuickTaskProject
    ? "https://ik.imagekit.io/zeta"
    : "https://ik.imagekit.io/wffo0jsx3/syde-prod";
  storageFB = isQuickTaskProject
    ? "https://firebasestorage.googleapis.com/v0/b/quicktask-prod-e2bc9.appspot.com"
    : "https://firebasestorage.googleapis.com/v0/b/sydework-api.appspot.com";
} else {
    env_Value = "dev";

  if (process.env.GATSBY_APP_ENV === "quicktask-dev") {
    ALGOLIA_APP_ID = "M74YSN7MXQ";
    ALGOLIA_API_KEY = "f44dd79ac582f90c79fc265809e8866f";
  } else {
    ALGOLIA_APP_ID = "M74YSN7MXQ";
    ALGOLIA_API_KEY = "f44dd79ac582f90c79fc265809e8866f";
  }
  if (process.env.GATSBY_APP_ENV === "quicktask-dev") {
    STRIPE_KEY =
      "pk_test_51Oc3jGChRLDqKMwoljZ2M3bPqNCn5ycF0oT89utRsCL4405wmTaMIc0twWhfF9aFgjNgM98alZ9h3CQHCZY3fuen00kFwCm1eV";
  } else {
    STRIPE_KEY = "pk_test_eVqJChIYUuO5Uo0joF9ka5fB00EJpSo6UV";
  }
  baseUrl = "https://us-central1-sydework-dev.cloudfunctions.net";
  endPoint = "https://ik.imagekit.io/wffo0jsx3/syde-dev";
  storageFB = "https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com";
}
if (isQuickTaskProject) {
  project = "quicktask";
  descriptionInfo = "TASKS MADE SIMPLE";
  country = "uk";
  countryCodeCapitalForStripe = "GB";
  currency = "gbp";
  currencyUnit = "Pounds";
  currencySymbol = "£";
  defaultLocationInfo = {lat: 51.530548, lng: -0.10172};
  shortDateFormatwith_DD = "DD MMM";
  shortDateFormatwith_dd = "dd MMM";
  longDateFormatWith_DD = "DD MMM, YYYY";
  longDateFormatWith_DDWithTime = "DD MMM, HH:mm";
  longDateFormatwithSeparator = "DD/MM/YYYY";
  title = "QuickTask";
  WebsiteLink = "https://quicktask.co.uk";
  shortWebsiteLink = "quicktask.co.uk";
  appStoreLink = "https://apps.apple.com/us/app/quicktask-uk/id6496674426";
  GooglePlayLink = "https://play.google.com/store/apps/details?id=com.zetaton.quicktask";
  yardWork = " yard work";
  CVname = "proof of ID";
  logoJPG =
    "https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com/o/quicktask_logos%2FQuickTaskicon.jpg?alt=media&token=45c27197-5d6d-43f9-8fa1-4cdbdfa45a85";
} else {
  logoJPG =
    "https://firebasestorage.googleapis.com/v0/b/sydework-dev.appspot.com/o/icon.jpg?alt=media&token=1ae7617e-2390-41c6-9e8b-40502efd7cfe";
  project = "sydetasker";
  descriptionInfo = "Post. Hire. Done.";
  country = "us";
  countryCodeCapitalForStripe = "US";
  currency = "usd";
  currencyUnit = "Cents";
  currencySymbol = "$";
  defaultLocationInfo = {lat: 37.774929, lng: -122.419416};
  shortDateFormatwith_DD = "MMM DD";
  shortDateFormatwith_dd = "MMM dd";
  longDateFormatWith_DD = "MMM DD, YYYY";
  longDateFormatWith_DDWithTime = "MMM DD, hh:mm A";
  longDateFormatwithSeparator = "YYYY-MM-DD";
  title = "Sydetasker";
  WebsiteLink = "https://sydetasker.com";
  shortWebsiteLink = "sydetasker.com";
  appStoreLink = "https://apps.apple.com/in/app/sydetasker/id1502353211#?platform=iphone";
  GooglePlayLink = "https://play.google.com/store/apps/details?id=ai.unicode.sydetasker";
  yardWork = " gardening ";
  CVname = "Resume";
}
export {
  ALGOLIA_APP_ID,
  ALGOLIA_API_KEY,
  STRIPE_KEY,env_Value,
  baseUrl,
  endPoint,
  storageFB,
  WebsiteLink,
  shortWebsiteLink,
  project,
  title,
  descriptionInfo,
  country,
  currency,
  currencySymbol,
  currencyUnit,
  defaultLocationInfo,
  shortDateFormatwith_DD,
  shortDateFormatwith_dd,
  longDateFormatWith_DD,
  longDateFormatWith_DDWithTime,
  longDateFormatwithSeparator,
  isQuickTaskProject,
  countryCodeCapitalForStripe,
  appStoreLink,
  GooglePlayLink,
  yardWork,
  logoJPG,
  CVname,
};
