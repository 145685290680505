import React from "react";
import {Link} from "gatsby";
import classNames from "classnames";
import { environment } from "./../../environments/quickTaskEnvironemt"
import {styled} from "@mui/system";
import {isQuickTaskProject, title} from "../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;
const iconButtonStyles = {
  iconButton: {
    transition: "0.3s",
    borderRadius: "50%",
    cursor: "pointer",
    padding: "6px 6px",
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButtonPrimary: {
    color: (theme: any) => isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
    "&:hover": {
      background: "rgba(78, 62, 253,0.13)",
    },
  },
  
  iconButtonSecondary: {
    color: "rgb(0,0,0,0.7)",
    "&:hover": {
      background: "rgba(235, 235, 235,1)",
    },
  },
};

const BaseIconButton = styled("div")(iconButtonStyles.iconButton);
const PrimaryIconButton = styled(BaseIconButton)(({theme}) => ({
  color: theme.palette.primary.main,
  "&:hover": {
    background: "rgba(78, 62, 253, 0.13)",
  },
}));
const SecondaryIconButton = styled(BaseIconButton)(iconButtonStyles.iconButtonSecondary);

interface BaseButtonProps {
  variant?: "primary" | "secondary";
  onClick?: () => void;
  icon?: any;
  to?: string;
  component?: "span" | "button";
  children?: React.ReactNode;
  style?: React.CSSProperties;
  key?: string;
}

function CustomIconButton(props: BaseButtonProps) {
  const IconComponent = props.variant === "primary" ? PrimaryIconButton : SecondaryIconButton;

  const base = (
    <IconComponent
      style={props.style}
      onClick={props.onClick}
      key={props.key}
      className={classNames({
        [`${iconButtonStyles.iconButton}`]: true,
      })}
    >
      {props.children}
    </IconComponent>
  );

  return props.to ? (
    props.to.includes("http") ? (
      <a href={props.to} rel="noopener noreferrer" target="_blank">
        {base}
      </a>
    ) : (
      <Link to={props.to}>{base}</Link>
    )
  ) : (
    base
  );
}

export default CustomIconButton;
