import {Notification} from "../../models/notification";
import {FaHandPointUp} from "react-icons/fa";
import React from "react";
import Text from "../data-display/text";

const getNotificationIcon = (type: typeof Notification.prototype.type) => {
    switch (type) {
        case "new_offer":
        case "offer_updated":
            return <FaHandPointUp color={"white"} size={14}/>;
    }
}

const getNotificationColor = (type: typeof Notification.prototype.type) => {
    switch (type) {
        case "new_offer":
        case "offer_updated":
            return "linear-gradient(rgb(50, 229, 206), rgb(30, 169, 156))";
    }
}

const notificationHasIcon = (type: typeof Notification.prototype.type) => {
    switch (type) {
        case "new_offer":
        case "offer_updated":
            return true;
        default:
            return false;
    }
}

const getNotificationBody = (notification: Notification) => {

    const style = { lineHeight: 1.2 }

    switch (notification.type) {
        case "new_offer":
            return <Text variant={"body2"} regular style={style}>
                <Text variant={"body2"} component={"span"} bold style={style}>{notification.firstName}</Text>
                {" "}bid{" "}
                <Text variant={"body2"} component={"span"} bold style={{ lineHeight: 1.3 }}>${notification.options["budget"]}</Text>
                {" "}on the task{" "}
                <Text variant={"body2"} component={"span"} bold style={style}>{notification.taskTitle}</Text>
            </Text>;
        default:
            return <Text variant={"body2"} regular style={style}>
                {notification.body}
            </Text>;
    }
}

export {getNotificationIcon, getNotificationColor, notificationHasIcon, getNotificationBody}