import React from "react";
import {Collapse} from "@mui/material";
import Text from "../data-display/text";

function FormErrorText(
    {
        error,
        showError,
        style
    } : {
        error?: Record<string, string | boolean> | string,
        showError?: boolean,
        style?: React.CSSProperties
    }) {
    let errorValue;

    if (typeof error === "string")
        errorValue=error;
    else
        errorValue=error?.text;

    return (
        <Collapse in={showError && errorValue!==undefined && errorValue!==""} style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: '8px 0', background: "rgba(500, 0, 0, 0.05)", borderRadius: "8px", padding: "8px 14px", ...style}}>
                <Text variant={"caption"} medium style={{color:"rgb(198,53,89)", marginBottom: "2px", minHeight: "12px", textTransform:"none"}} >
                    {errorValue}
                </Text>
            </div>
        </Collapse>
    )
}

export default FormErrorText;