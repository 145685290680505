import React, { useEffect, useState } from "react";
import Text from "../../data-display/text";
import FormInput, { BaseInputProps } from "../../input-feilds/form-input";
import { useTheme } from "@mui/system";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { Theme } from "@mui/material/styles";
import { Task } from "../../../models/task";
import { NumericFormat } from "react-number-format";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useMediaQuery } from "@mui/material";
import { currencySymbol, isQuickTaskProject } from "../../../utils/constant.urls";
import { environment } from "../../../environments/quickTaskEnvironemt";
const QuikTaskMainColor = environment.mainColor;

class BudgetData {
  budgetType: "total" | "hourly";
  totalBudget: string;
  hourlyBudget: string;
  hours: number;
  noOfTaskers: number;

  constructor(data?: { [field: string]: any }) {
    this.budgetType = data?.budgetType ?? "total";
    this.totalBudget = data?.totalBudget ?? "0";
    this.hourlyBudget = data?.hourlyBudget ?? "0";
    this.hours = data?.hours ?? 1;
    this.noOfTaskers = data?.noOfTaskers ?? 1;
  }

  get isValid() {
    let valid = true;
    if (this.budgetType === "total") {
      if (parseInt(!this.totalBudget ? "0" : this.totalBudget.replaceAll(",", "")) < 10)
        valid = false;
    } else {
      if (parseInt(!this.hourlyBudget ? "0" : this.hourlyBudget.replaceAll(",", "")) < 15) {
        valid = false;
      } else if (this.hours === 0) {
        valid = false;
      } else if (this.totalBudget.toString().includes(".")) {
        valid = false;
      }
    }

    return valid;
  }

  get errors() {
    const path = window.location.pathname.includes("/increase");
    const budget =
      this.budgetType === "total"
        ? parseInt(!this.totalBudget ? "0" : this.totalBudget.replaceAll(",", ""))
        : parseInt(!this.hourlyBudget ? "0" : this.hourlyBudget.replaceAll(",", ""));
    //to increase price with less than 10 dollars
    if (this.budgetType === "total" && budget > 9999) {
      return `The max. value for the budget is ${currencySymbol}9,999`;
    } else if (!path && this.budgetType === "total" && budget < 10) {
      return `The min. value for the budget is ${currencySymbol}10`;
    } else if (this.budgetType === "hourly") {
      if (budget * this.hours > 9999 || budget * this.hours * this.noOfTaskers > 9999) {
        return `The max. value for the budget is ${currencySymbol}9,999`;
      } else if (budget < 15) {
        return `The min. hourly rate is ${currencySymbol}15`;
      } else if (this.hours === 0) {
        return `The min. required hours is one`;
      } else if (parseInt(!this.budget() ? "0" : this.budget()) > 9999) {
        return `The max. value for the budget is${currencySymbol}9,999`;
      } else if (this.totalBudget.toString().includes(".")) {
        return `The max. value for the budget is ${currencySymbol}9,999`;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  get isMultipleTaskers() {
    return this.noOfTaskers > 1;
  }

  budget(future = false) {
    if (this.budgetType === "total") {
      return this.totalBudget.trim();
    } else {
      let t = this.noOfTaskers ?? 1;
      return ((future ? t + 1 : t) * (parseInt(this.hourlyBudget) * (this.hours ?? 1))).toString();
    }
  }

  getFeePercentage() {
    const budget = parseInt(this.budget ? this.budget().replaceAll(",", "") : "0");

    if (isQuickTaskProject) {
      if (budget < 500) {
        return 0.15; 
      } else if (budget <= 1000) {
        return 0.10;
      } else {
        return 0.075;
      }
    } else {
      return 0.15;
    }
  }

  get serviceFees() {
    const budget = parseInt(this.budget ? this.budget().replaceAll(",", "") : "0");
    const serviceFee = budget * this.getFeePercentage();
    if (serviceFee) {
      return (
        <NumericFormat
          value={serviceFee.toFixed(2)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
        />
      );
    } else return "0.00";
  }

  get receivedAmount() {
    const budget = parseInt(this.budget ? this.budget().replaceAll(",", "") : "0");
    const serviceFee = budget * this.getFeePercentage();
    if (budget) {
      return (
        <NumericFormat
          value={(budget - serviceFee).toFixed(2)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
        />
      );
    } else return "0.00";
  }

  toJson(): BudgetData {
    return Object.assign({}, this);
  }
}

interface BudgetInputProps extends BaseInputProps {
  task: Task;
  initialBudgetData?: BudgetData;
  initialTypeToView?: "total" | "hourly";
  disableSwitchingBetweenTabs?: boolean;
  noOfTaskers?: number;
  allowEditingNoOfTaskers?: boolean;
  placeholder?: string;
  onBudgetChange?: (newValue: BudgetData) => void;
}

const BudgetInput = ({
  onBudgetChange,
  onChange,
  initialBudgetData,
  initialTypeToView = "total",
  value,
  disableSwitchingBetweenTabs = false,
  allowEditingNoOfTaskers = true,
  placeholder,
}: BudgetInputProps) => {
  const theme = useTheme<any>();
  const palette_Color = isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main;

  const styles = {
    rootTabContainer: {
      transform: "translateY(4px)",
      background: "rgb(230,230,230)",
      borderRadius: "20px",
      width: "auto",
      height: "28px",
      minHeight: "unset",
    },
    rootTab: {
      textTransform: "none",
      fontSize: theme.typography.pxToRem(15),
      minHeight: "unset",
      height: "28px",
      minWidth: "unset",
      width: "120px",
    },
    indicatorTab: {
      display: "flex",
      height: "100%",
      borderRadius: "16px",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
      "& > div": {
        width: "100%",
        backgroundColor: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
      },
    },
    hover: {
      background: "transparent",
      padding: "10px",
      margin: "0 2px",
      width: "18px",
      height: "18px",
      cursor: "pointer",
      borderRadius: "50%",
      "&:hover": {
        background: "rgb(235,235,235)",
      },
    },
  };
  const [budgetData, setBudgetData] = useState<BudgetData>(
    initialBudgetData ? initialBudgetData : new BudgetData()
  );
  const [tab, setTab] = useState(initialTypeToView === "total" ? 0 : 1);
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setTab(newValue);
    setBudgetData((b) => {
      let t = new BudgetData(b.toJson());
      t.budgetType = newValue === 0 ? "total" : "hourly";
      return t;
    });
  };

  useEffect(() => {
    if (initialBudgetData) {
      setBudgetData(initialBudgetData);
      onBudgetChange(initialBudgetData);
    }
  }, []);

  useEffect(() => {
    onBudgetChange(budgetData);
  }, [budgetData]);

  return (
    <>
      {!disableSwitchingBetweenTabs && (
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "12px" }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              "& .MuiTabs-root": styles.rootTabContainer,
              "& .MuiTabs-indicator": styles.indicatorTab,
            }}
            value={tab}
            onChange={handleChange}
            aria-label="budget-types"
          >
            <Tab
              disableRipple
              sx={{
                "& .MuiTab-root": styles.rootTab,
              }}
              label={
                <Text variant={"caption"} style={{ color: tab === 0 ? "white" : "grey" }} bold>
                  Flat Rate
                </Text>
              }
            />
            <Tab
              disableRipple
              sx={{
                "& .MuiTab-root": styles.rootTab,
              }}
              label={
                <Text variant={"caption"} style={{ color: tab === 1 ? "white" : "grey" }} bold>
                  Hourly rate
                </Text>
              }
            />
          </Tabs>
        </div>
      )}
      {tab === 0 && (
        <FormInput
          autoComplete={"off"}
          rootStyle={{ width: "100%" }}
          defaultValue={
            initialBudgetData?.totalBudget !== "0" ? "" + initialBudgetData?.totalBudget : null
          }
          name={"budget"}
          onChange={(el) => {
            let val = el?.target?.value;
            setBudgetData((b) => {
              let t = new BudgetData(b.toJson());
              t.totalBudget = val;
              return t;
            });
          }}
          placeholder={
            disableSwitchingBetweenTabs ? (placeholder ? placeholder : "Your Offer") : "Budget"
          }
          type={"number"}
          startAdornment={
            <Text variant={"body2"} medium style={{ marginLeft: "16px" }}>
              {currencySymbol}
            </Text>
          }
        />
      )}
      {tab === 1 && (
        <>
          <div style={{ display: "flex" }}>
            <FormInput
              onChange={(el) => {
                let val = el?.target?.value ?? "";
                setBudgetData((b) => {
                  let t = new BudgetData(b.toJson());
                  t.hourlyBudget = val;
                  return t;
                });
              }}
              autoComplete={"off"}
              rootStyle={{ width: "100%", flex: 1 }}
              defaultValue={
                initialBudgetData?.hourlyBudget !== "0" ? initialBudgetData?.hourlyBudget : null
              }
              name={"budget"}
              placeholder={
                disableSwitchingBetweenTabs ? (placeholder ? placeholder : "Offer") : "Budget"
              }
              type={"number"}
              startAdornment={
                <Text variant={"body2"} medium style={{ marginLeft: "16px" }}>
                  {currencySymbol}
                </Text>
              }
              endAdornment={
                <Text
                  variant={"caption"}
                  medium
                  style={{
                    opacity: "0.3",
                    wordBreak: "unset",
                    whiteSpace: "unset",
                    flexShrink: 0,
                    marginRight: "12px",
                  }}
                >
                  / hr
                </Text>
              }
            />
            <div
              style={{
                flexShrink: 0,
                padding: "8px",
                transform: "translateY(-2px)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text variant={"h6"} medium>
                ×
              </Text>
            </div>
            <FormInput
              autoComplete={"off"}
              rootStyle={{ width: "100%", flex: 1 }}
              onChange={(el) => {
                let s = el?.target?.value ?? "";
                let val = s ? parseInt(s) : 1;
                setBudgetData((b) => {
                  let t = new BudgetData(b.toJson());
                  t.hours = val;
                  return t;
                });
              }}
              defaultValue={initialBudgetData?.hours?.toString() ?? "1"}
              name={"budget"}
              placeholder={"Hours"}
              type={"number"}
              startAdornment={
                <Text variant={"body2"} medium style={{ marginLeft: "16px" }}>
                  {""}
                </Text>
              }
              endAdornment={
                <Text
                  variant={"caption"}
                  medium
                  style={{
                    opacity: "0.3",
                    wordBreak: "unset",
                    whiteSpace: "unset",
                    flexShrink: 0,
                    marginRight: "12px",
                  }}
                >
                  hrs.
                </Text>
              }
            />
          </div>
          {allowEditingNoOfTaskers && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Text variant={"body2"} medium style={{ marginTop: "8px" }} selectable={false}>
                How many taskers do you need?
              </Text>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    ...(styles.hover as React.CSSProperties),
                  }}
                  onClick={() => {
                    if (budgetData.noOfTaskers > 1)
                      setBudgetData((b) => {
                        let t = new BudgetData(b.toJson());
                        t.noOfTaskers--;
                        return t;
                      });
                  }}
                >
                  <FaMinusCircle
                    style={{
                      color: budgetData.noOfTaskers > 1 ? palette_Color : "rgb(200,200,200)",
                    }}
                    size={18}
                  />
                </div>
                <Text
                  variant={"body2"}
                  medium
                  style={{ display: "flex", alignItems: "center", marginLeft: "15px" }}
                  selectable={false}
                >
                  {budgetData.noOfTaskers}
                </Text>
                <div
                  style={{
                    ...(styles.hover as React.CSSProperties),
                  }}
                  onClick={() => {
                    if (parseInt(budgetData.budget(true)) <= 9999)
                      setBudgetData((b) => {
                        let t = new BudgetData(b.toJson());
                        t.noOfTaskers++;
                        return t;
                      });
                  }}
                >
                  <FaPlusCircle
                    style={{
                      color:
                        parseInt(budgetData.budget(true)) <= 9999
                          ? palette_Color
                          : "rgb(200,200,200)",
                    }}
                    size={18}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export { BudgetData };
export default BudgetInput;
