import {project, isQuickTaskProject} from "../utils/constant.urls";

export default class Address {
  id: string | null = "";
  placeId: string | null = "";
  project: string | null = "";
  addressLineOne: string | null = "";
  addressLineTwo: string | null = "";
  city: string | null = "";
  state: string | null = "";
  country: string | null = "";
  lat: number | null = null;
  lng: number | null = null;
  postCode: string | null = "";
  zipCode: string | null = "";

  constructor(data?: Partial<Address>) {
    this.id = data?.id ?? null;
    this.project = project ?? null;
    this.placeId = data?.placeId ?? null;
    this.addressLineOne = data?.addressLineOne ?? null;
    this.addressLineTwo = data?.addressLineTwo ?? null;
    this.city = data?.city ?? null;
    this.state = data?.state ?? null;
    this.country = data?.country ?? null;
    this.lat = data?.lat ?? null;
    this.lng = data?.lng ?? null;
    if (isQuickTaskProject) {
      this.postCode = data?.postCode ?? null;
    } else {
      this.zipCode = data?.zipCode ?? null;
    }
  }

  toJson(): Address {
    return Object.assign({}, this);
  }

  get primaryLine() {
    return this.addressLineOne;
  }

  get secondaryLine() {
    return this.city + ", " + this.state;
  }

  get secondaryLineWithZipCode() {
    if (isQuickTaskProject) {
      return (this.city + ", " + this.state + " " + this.postCode).trim();
    } else {
      return (this.city + ", " + this.state + " " + this.zipCode).trim();
    }
  }
}
