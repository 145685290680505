import React, {useEffect, useState} from "react";
import Text from "../../../components/data-display/text";
import {useTheme} from "@mui/system";
import {GrayColor} from "../../../constants/constant-ui";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/index.reducers";
import Avatar from "../../../components/avatar/avatar";
import firebase, {auth, tasks, users} from "../../../utils/firebase.utils";
import {UserData} from "../../../models/user-data";
import StarRating from "../../../components/reviews-ratings/star-rating";
import {FiCheck} from "react-icons/fi";
import MiniButton from "../../buttons/mini-button";
import axios from "axios";
import {baseUrl, isQuickTaskProject, project} from "../../../utils/constant.urls";
import CircularProgressBar from "../../../components/progress-bars/circular-progress-bar";
import {environment} from "../../../environments/quickTaskEnvironemt";
const QuikTaskMainColor = environment.mainColor;

const InviteTaskers = ({taskId, invitedTasker}: {taskId: string; invitedTasker?: string}) => {
  const theme = useTheme();
  const styles = {
    root: {
      background: "white",
      transition: "0.3s",
      border: "1.5px solid rgb(245,245,245)",
      borderBottom: "none",
      marginBottom: "20px",
    },
    miniBtn: {
      //background: "#e5e1ff",
      background: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
      marginTop: "15px",
      alignSelf: "flex-end",
    },
    taskerCard: {
      display: "flex",
      padding: "12px 18px",
      borderBottom: "1.5px solid rgb(245, 245, 245)",
    },
    greenTick: {
      padding: "2px 15px",
      background: "#34c4a2",
      borderRadius: "50px",
      alignSelf: "flex-end",
    },
  };
  const palette_Color = isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main;

  const {userData} = useSelector((state: RootState) => state.userData);

  const [taskers, setTaskers] = useState<UserData[]>([]);
  const [invitedList, setInvitedList] = useState<string[]>([]);

  useEffect(() => {
    let usersList: UserData[] = [];
    if (userData?.previouslyHired?.length) {
      let previouslyHiredUIDs = [];
      for (let index = 0; index < userData?.previouslyHired?.length; index++) {
        const element = userData?.previouslyHired[index];
        if (previouslyHiredUIDs?.includes(element) === false) {
          previouslyHiredUIDs.push(element);
        }
      }
      const previouslyHiredList =
        previouslyHiredUIDs?.length > 10 ? previouslyHiredUIDs?.slice(0, 9) : previouslyHiredUIDs;
      users
        .where("uid", "in", previouslyHiredList)
        .where("project", "==", project)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            snapshot.docs.forEach((doc) => {
              const data = doc.data();
              usersList.push(new UserData(data));
            });
            setTaskers(usersList);
          }
        });
    }
    if (invitedTasker) {
      inviteTasker(invitedTasker);
      users
        .doc(invitedTasker)
        .get()
        .then((doc) => {
          const data = doc.data();
          if (data && data.project === "project") {
            let arr: UserData[] = usersList;
          if (!usersList.find((x) => x.uid === data.uid)) arr = [new UserData(data), ...usersList];
          setTaskers(arr);
        }else {
            console.log("you have no access to that project")
          }
        });
    }
  }, [invitedTasker, userData]);

  const inviteTasker = (uid) => {
    setInvitedList([uid, ...invitedList]);
    auth.currentUser
      .getIdToken()
      .then((userToken) => {
        return axios.post(
          `${baseUrl}/inviteToTask?project=${project}`,
          {taskId: taskId, userId: uid},
          {
            headers: {
              Authorization: userToken,
            },
          }
        );
      })
      .then(() => {
        tasks.doc(taskId).update({
          invitedTaskers: firebase.firestore.FieldValue.arrayUnion(uid),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div
      style={{
        ...(styles.root as React.CSSProperties),
      }}
    >
      <div style={{padding: "15px", borderBottom: "1.5px solid rgb(245,245,245)"}}>
        <Text variant={"subtitle2"} medium style={{color: palette_Color}}>
          Invite Taskers
        </Text>
        <Text variant={"caption"} medium style={{color: GrayColor}}>
          Invite some of your previously hired Taskers to apply for your Task again.
        </Text>
      </div>
      {taskers.length ? (
        taskers.map((tasker, index) => (
          <div
            key={tasker.uid + "_" + index}
            style={{
              ...(styles.taskerCard as React.CSSProperties),
            }}
          >
            <Avatar userId={tasker?.slug_title} profilePicture={tasker.profilePicture} size={30} />
            <div style={{width: "15px"}} />
            <div>
              <Text variant={"subtitle2"} medium>
                {tasker.name}
              </Text>
              <StarRating
                totalAverage={tasker.workerReviewIndex?.totalAverage}
                totalCount={tasker.workerReviewIndex?.totalCount}
              />
            </div>
            <div style={{marginLeft: "auto"}}>
              {invitedList.length && invitedList.includes(tasker.uid) ? (
                <span
                  style={{
                    ...(styles.greenTick as React.CSSProperties),
                  }}
                >
                  <FiCheck style={{stroke: "white"}} />
                </span>
              ) : (
                <MiniButton
                  style={{
                    ...(styles.miniBtn as React.CSSProperties),
                  }}
                  onClick={() => inviteTasker(tasker.uid)}
                >
                  Invite
                </MiniButton>
              )}
            </div>
          </div>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "24px 0",
          }}
        >
          <CircularProgressBar />
        </div>
      )}
    </div>
  );
};

export default InviteTaskers;
